import React, { useEffect } from 'react';
import {
  Tag,
  message,
  Tooltip,
  Spin,
  Divider,
  Input,
  Button,
  Tabs,
  Form,
} from 'antd';
import Api from '../../../utils/api';
import config from '../../../config/index';
import styled from '@emotion/styled';
const { TabPane } = Tabs;

const InnerIcon = styled.span`
  font-size: 36px;
  padding-top: 5px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
`;

const InnerImage = styled.img`
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
`;

const InnerText = styled.span`
  display: block;
  white-space: nowrap;
  text-align: center;
  transform: scale(0.83);
`;

const List = styled.ul`
  overflow: hidden;
  direction: ltr;
  list-style: none;
  padding-inline-start: 0;
`;

const Child = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 25%;
  margin: 3px 0;
  overflow: hidden;
  color: #555;
  text-align: center;
  list-style: none;
  background-color: inherit;
  border-radius: 10px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #8ecafe;
  }

  &:hover span img {
    transform: scale(1.4);
  }
`;

const App = ({ onClickedIcon, selected, form }) => {
  const { getFieldValue, setFieldsValue } = form;
  const getfieldicon = getFieldValue('icon');
  const [images, setImages] = React.useState([]);
  const [recommendedList, setRecommendedList] = React.useState([]);
  const [recommendationOption, setRecommendationOption] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [position, setPosition] = React.useState(['left', 'right']);
  const [loading, setLoading] = React.useState(true);

  const getIcons = async () => Api.get('/backgrounds');
  const getCategories = async () => Api.get('/backgrounds/categories');

  useEffect(async () => {
    const catData = await getCategories();
    const iconData = await getIcons();
    setCategories(catData.data);
    setQuery(catData.data[0].category);
    setImages(iconData.data.filter((v) => v.size === 'small'));
    setLoading(false);
    setRecommendedList([]);
    setRecommendationOption(false);
  }, []);

  const onChange = (e) => {
    setQuery(e.target.value);
    setShowAll(false);
    setRecommendedList([]);
    setRecommendationOption(false);
  };

  const OperationsSlot = {
    left: (
      <>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues !== currentValues
          }
          label="Icon">
          {(form) => (
            <Form.Item style={{ height: 120 }} name={('icon', 'filename')}>
              {form.getFieldValue('images')?.small?.url ||
              form.getFieldValue('images')?.medium?.url ? (
                <Tooltip title="Show Recommended">
                  <Button
                    type="dashed"
                    onClick={() => {
                      // if (form.getFieldValue('name')) {
                      //   setQuery(form.getFieldValue('name'));
                      // } else {
                      //   message.warn('Not implemented yet');
                      // }
                    }}
                    style={{
                      textTransform: 'capitalize',
                      cursor: 'default',
                      height: 120,
                    }}
                    icon={
                      <InnerImage
                        style={{ marginRight: 10 }}
                        src={
                          form.getFieldValue('images')?.small?.url ||
                          form.getFieldValue('images')?.medium?.url
                        }
                        height="100"
                      />
                    }
                    block></Button>
                </Tooltip>
              ) : (
                <Button
                  place
                  onClick={() => {
                    // if (form.getFieldValue('name')) {
                    //   setQuery(form.getFieldValue('name'));
                    // } else {
                    //   message.warn("Not implemented yet")
                    // }
                  }}
                  style={{ textTransform: 'capitalize' }}
                  block>
                  Show Recommended
                </Button>
              )}
            </Form.Item>
          )}
        </Form.Item>
        <div style={{ marginBottom: -20 }}></div>
        <Input placeholder="Search" allowClear onChange={onChange} />
        <Divider />
      </>
    ),
    right: <></>,
  };
  const slot = React.useMemo(() => {
    if (position.length === 0) return null;

    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {}
    );
  }, [position]);

  return (
    <div style={{ maxHeight: 500, overflow: 'hidden' }}>
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey={'2'}
          tabPosition={'left'}
          tabBarExtraContent={slot}
          centered
          style={{ height: 600 }}
          onTabClick={(c) => {
            setQuery(categories[c].category);
            setShowAll(false);
          }}>
          {categories.map(({ category: cat, count: count }, i) => (
            <TabPane tab={cat + '(' + count + ')'} key={i.toString()}>
              <List style={{ maxHeight: 500, overflow: 'auto' }}>
                {!!recommendationOption
                  ? recommendedList.map((item, i) => (
                      <IconList
                        onClickedIcon={onClickedIcon}
                        item={item}
                        i={i}
                      />
                    ))
                  : images
                      .filter((item) => {
                        return (
                          (query &&
                            query
                              .split(' ')
                              .some((r) =>
                                item.noun.find(
                                  (noun) =>
                                    noun
                                      .toLowerCase()
                                      .indexOf(r.toLowerCase()) > -1
                                )
                              )) ||
                          (query &&
                            item.key
                              .toLowerCase()
                              .indexOf(query.toLowerCase()) > -1) ||
                          item.category
                            .toLowerCase()
                            .indexOf(query.toLowerCase()) > -1
                        );
                      })
                      .map((item, i) => (
                        <IconList
                          onClickedIcon={onClickedIcon}
                          item={item}
                          i={i}
                        />
                      ))}
              </List>
            </TabPane>
          ))}
        </Tabs>
      </Spin>
    </div>
  );
};

const IconList = ({ item, i, onClickedIcon }) => {
  return (
    <Child onClick={() => onClickedIcon(item)}>
      <InnerIcon>
        <InnerImage
          // src={`data:image/png;base64, ${item.base64}`}
          src={config.STATIC_URL + 'backgrounds/' + item.filename}
          height="180"
          width="180"
        />
      </InnerIcon>
    </Child>
  );
};

export default App;
