import Api from '../../../utils/api';

export const getRoutines = async () => Api.get('/routines/routine-habits');
export const getJourneys = async () => Api.get('/journeys');
export const getChallenges = async () => Api.get('/challenges');

export const getCategoryRoutines = async () => Api.get('/routines/category');
export const getCategoryJourneys = async () => Api.get('/journeys/category');
export const getCategoryChallenges = async () =>
  Api.get('/challenges/category');

export const updateCategory = async (data) =>
  Api.put('/routines/category', data);
export const createCategory = async (data) =>
  Api.post('/routines/category', data);
export const deleteCategory = async (data) =>
  Api.post('/routines/category/delete', data);
