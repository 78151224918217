import { Action, Dispatch } from 'redux';
import Api from '../../../utils/api';
import {
  FETCH_CHALLENGE_ERROR,
  FETCH_CHALLENGE_START,
  FETCH_CHALLENGE_SUCCESS,
  CHALLENGE_PUSH,
  CHALLENGE_UPDATE,
  CHALLENGE_ARCHIVE,
} from './types';

const getChallenges = async () => Api.get('/challenges');

export function makeFetchChallenges() {
  return async function (dispatch) {
    try {
      await startAction(FETCH_CHALLENGE_START, dispatch);
      const response = await getChallenges();
      const { data } = response;
      console.log('dispatching', data);
      dispatch({
        type: FETCH_CHALLENGE_SUCCESS,
        data,
      });
    } catch (error) {
      await errorAction(FETCH_CHALLENGE_ERROR, dispatch, error);
    }
  };
}

export function makePushChallenge(data) {
  return async function (dispatch) {
    dispatch({
      type: CHALLENGE_PUSH,
      data,
    });
  };
}

export function makeUpdateChallenge(key, data) {
  return async function (dispatch) {
    console.log('UPDATE_CHALLENGE', key, data);
    dispatch({
      type: CHALLENGE_UPDATE,
      key,
      data,
    });
  };
}

export function makeArchiveChallenge(id) {
  return async function (dispatch) {
    dispatch({
      type: CHALLENGE_ARCHIVE,
      id,
    });
  };
}

export const startAction = async (type, dispatch) => {
  await dispatch({
    type,
  });
};

export const errorAction = async (type, dispatch, error) => {
  await dispatch({
    type,
    error: error.statusText || error.data,
  });
};
