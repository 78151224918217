import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectJourney = (state) => state.Journey || initialState;

const selectJourneyData = createSelector(
  selectJourney,
  (Journey) => Journey.data
);
const selectJourneyLoading = createSelector(
  selectJourney,
  (Journey) => Journey.loading
);
const selectJourneyError = createSelector(
  selectJourney,
  (Journey) => Journey.error
);

export { selectJourneyData, selectJourneyLoading, selectJourneyError };
