import React from 'react';
import { Form, Input } from 'antd';

import BackgroundList from '../Background/icontab';

import config from '../../../config/index';
const createRoutine = ({
  form,
  onFinish,
  onFinishFailed,
  setSelectedBackgroundKey,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{ language: 'english' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item name="_id" label="_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="key" label="Key" hidden>
        <Input />
      </Form.Item>
      Icon
      <div style={{ marginBottom: 5 }}></div>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => prevValues !== curValues}>
        {(form) => {
          return (
            <BackgroundList
              form={form}
              onClickedIcon={(e) => {
                const url = config.STATIC_URL + 'backgrounds/' + e.filename;
                setSelectedBackgroundKey(e.key);
                console.log(e);
                form.setFieldsValue({
                  images: {
                    small: {
                      url: url,
                    },
                  },
                });
              }}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default createRoutine;
