import React from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from './routes';
import { selectToken } from './containers/DashboardPage/Auth/selectors';
const Router = () => {
  const isLoggedIn = useSelector(selectToken);
  const routing = useRoutes(routes(isLoggedIn));
  return <>{routing}</>;
};

export default Router;
