import { Navigate, Outlet } from 'react-router-dom';

import Dashboard from './containers/DashboardPage';
import NotFound from './containers/NotFoundPage';
import LandingPage from './containers/LandingPage';
import Auth from './containers/DashboardPage/Auth';
import Main from './containers/DashboardPage/Main';
import Habit from './containers/DashboardPage/Habit';
import Icon from './containers/DashboardPage/Icon';
import Background from './containers/DashboardPage/Background';
import Journey from './containers/DashboardPage/Journey';
import JourneyBuilder from './containers/DashboardPage/JourneyBuilder';
import Routine from './containers/DashboardPage/Routine';
import RoutineBuilder from './containers/DashboardPage/RoutineBuilder';
import CategoryRoutineBuilder from './containers/DashboardPage/CategoryBuilder/routine';
import Challenge from './containers/DashboardPage/Challenge';
import ChallengeBuilder from './containers/DashboardPage/ChallengeBuilder';
import Test from './containers/DashboardPage/Test';

const routes = (isLoggedIn) => [
  {
    path: '/dashboard',
    element: isLoggedIn ? <Dashboard /> : <Navigate to="/login" />,
    children: [
      { path: '/main', element: <Main /> },
      { path: '/journey', element: <Journey /> },
      { path: '/journey-builder', element: <JourneyBuilder /> },
      { path: '/routine', element: <Routine /> },
      { path: '/routine-builder', element: <RoutineBuilder /> },
      {
        path: '/category-routine-builder',
        element: <CategoryRoutineBuilder />,
      },
      { path: '/challenge', element: <Challenge /> },
      { path: '/challenge-builder', element: <ChallengeBuilder /> },
      { path: '/habit', element: <Habit /> },
      { path: '/icon-editor', element: <Icon /> },
      { path: '/background-editor', element: <Background /> },
      { path: '/test', element: <Test /> },
      { path: '/', element: <Navigate to="/dashboard/main" /> },
      {
        path: '*',
        element: <NotFound />,
      },
      //   {
      //     path: 'member',
      //     element: <Outlet />,
      //     children: [
      //       { path: '/', element: <MemberGrid /> },
      //       { path: '/add', element: <AddMember /> },
      //     ],
      //   },
    ],
  },
  {
    path: '/',
    element: <LandingPage />,
  },
  {
    path: '/privacy',
    element: <LandingPage />,
  },
  {
    path: '/guide',
    element: <LandingPage />,
  },
  {
    path: '/terms',
    element: <LandingPage />,
  },
  {
    path: '/mindrobe/privacy',
    element: <LandingPage />,
  },
  {
    path: '/mindrobe/terms',
    element: <LandingPage />,
  },
  {
    path: '/login',
    element: !isLoggedIn ? <Auth /> : <Navigate to="/dashboard/main" />,
  },
  {
    path: '/dash',
    element: !isLoggedIn ? <Auth /> : <Navigate to="/dashboard/main" />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

export default routes;
