import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectChallenge = (state) => state.Challenge || initialState;

const selectChallengeData = createSelector(
  selectChallenge,
  (Challenge) => Challenge.data
);
const selectChallengeLoading = createSelector(
  selectChallenge,
  (Challenge) => Challenge.loading
);
const selectChallengeError = createSelector(
  selectChallenge,
  (Challenge) => Challenge.error
);

export { selectChallengeData, selectChallengeLoading, selectChallengeError };
