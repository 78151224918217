import { Action, Dispatch } from 'redux';
import Api from '../../../utils/api';
import {
  FETCH_ROUTINE_ERROR,
  FETCH_ROUTINE_START,
  FETCH_ROUTINE_SUCCESS,
  ROUTINE_PUSH,
  ROUTINE_UPDATE,
  ROUTINE_ARCHIVE,
} from './types';

const getRoutines = async () => Api.get('/routines');

export function makeFetchRoutines() {
  return async function (dispatch) {
    try {
      await startAction(FETCH_ROUTINE_START, dispatch);
      const response = await getRoutines();
      const { data } = response;
      console.log('dispatching', data);
      dispatch({
        type: FETCH_ROUTINE_SUCCESS,
        data,
      });
    } catch (error) {
      await errorAction(FETCH_ROUTINE_ERROR, dispatch, error);
    }
  };
}

export function makePushRoutine(data) {
  return async function (dispatch) {
    dispatch({
      type: ROUTINE_PUSH,
      data,
    });
  };
}

export function makeUpdateRoutine(key, data) {
  return async function (dispatch) {
    console.log('UPDATE_ROUTINE', key, data);
    dispatch({
      type: ROUTINE_UPDATE,
      key,
      data,
    });
  };
}

export function makeArchiveRoutine(id) {
  return async function (dispatch) {
    dispatch({
      type: ROUTINE_ARCHIVE,
      id,
    });
  };
}

export const startAction = async (type, dispatch) => {
  await dispatch({
    type,
  });
};

export const errorAction = async (type, dispatch, error) => {
  await dispatch({
    type,
    error: error.statusText || error.data,
  });
};
