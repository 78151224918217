import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import './index.css';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Tooltip,
  Form,
  Typography,
  Row,
  Col,
  Button,
  Modal,
  message,
  Divider,
  Switch,
} from 'antd';
import {
  EditOutlined,
  EditTwoTone,
  SaveTwoTone,
  DeleteTwoTone,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  FileExclamationTwoTone,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeFetchChallenges,
  makePushChallenge,
  makeUpdateChallenge,
  makeArchiveChallenge,
} from './actions';
import {
  selectChallengeData,
  selectChallengeLoading,
  selectChallengeError,
} from './selectors';
import CreateChallengeForm from './form/createChallenge';
import Api from '../../../utils/api';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const [form] = Form.useForm();
  const [challengeForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [furyMode, setFuryMode] = useState(true);
  const [updateMode, setUpdateMode] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [archiveConfirmLoading, setArchiveConfirmLoading] = useState(false);
  const [archivePopVisible, setArchivePopVisible] = useState(false);
  const [modalText, setModalText] = React.useState('Content of the modal');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const challengeDataSelector = useSelector(selectChallengeData);
  const challengeLoadingSelector = useSelector(selectChallengeLoading);
  const challengeErrorSelector = useSelector(selectChallengeError);
  const onFetchChallenge = useCallback(
    () => dispatch(makeFetchChallenges()),
    [dispatch]
  );
  const onPushChallenge = useCallback(
    (data) => dispatch(makePushChallenge(data)),
    [dispatch]
  );
  const onUpdateChallenge = useCallback(
    (key, data) => dispatch(makeUpdateChallenge(key, data)),
    [dispatch]
  );
  const onArchiveChallenge = useCallback(
    (id) => dispatch(makeArchiveChallenge(id)),
    [dispatch]
  );

  useEffect(() => {
    setLoading(true);
    onFetchChallenge();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(challengeDataSelector) &&
      challengeDataSelector.length >= 0 &&
      loading
    ) {
      setData(challengeDataSelector);
    }
  }, [challengeDataSelector]);

  useEffect(() => {
    if (challengeErrorSelector) {
      // console.log('SELECTOR_ERROR', challengeErrorSelector);
      message.error(challengeErrorSelector || 'Error occured');
      setLoading(false);
    }
  }, [challengeErrorSelector]);

  const showModal = () => {
    const furymode = localStorage.getItem('furymode');
    setFuryMode(furymode ? Boolean(JSON.stringify(furymode)) : true);
    setVisible(true);
  };

  const createChallengeAction = async (values) => {
    const { name, description, language, translation, icon, font } = values;
    if (name) {
      try {
        const res = await createChallenge({
          name,
          description,
          language,
          translation,
          icon,
          font,
        });
        message.success('Challenge created');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish', err);
        throw new Error(err);
      }
    }
  };

  const createChallenge = async (data) => Api.post('/challenges', data);

  const updateChallenge = async (data) => Api.put('/challenges', data);

  const archiveChallenge = async (id, status) =>
    Api.put('/challenges/' + id + '/archive/' + status);

  const updateChallengeAction = async (values) => {
    const { _id, key, name, description, language, translation, icon, font } =
      values;
    console.log('key', {
      key,
      _id,
    });
    if (_id && key) {
      try {
        const res = await updateChallenge({
          _id,
          key,
          name,
          description,
          language,
          translation,
          icon,
          font,
        });
        message.success('Challenge Updated');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish updated', err);
        throw new Error(err);
      }
    } else {
      message.error('Bir hata oluştu code:3');
    }
  };

  const onCreate = async (values) => {
    console.log('Received values of form: ', values);
    try {
      if (updateMode) {
        const response = await updateChallengeAction(values);
        if (response.status) {
          save(values.key, response.data);
        }
      } else {
        const { data } = await createChallengeAction(values);
        handleAdd(data);
      }
      if (!furyMode || updateMode) {
        setVisible(false);
      }
      challengeForm.resetFields();
    } catch (err) {
      console.log("oncreate'de error", err);
    }
    setConfirmLoading(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // setModalText('The modal will be closed after two seconds');
    challengeForm
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  const handleClear = () => {
    challengeForm.resetFields();
  };

  const handleCancel = async () => {
    if (updateMode) {
      handleClear();
      console.log('UPDATE MODE WILL BE OFF');
      setUpdateMode(false);
    }
    console.log('Clicked cancel button');
    setVisible(false);
  };

  const onChangeFuryMode = (data) => {
    localStorage.setItem('furymode', JSON.stringify(data));
    setFuryMode(data);
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    console.log('data', record);
    setUpdateMode(true);
    challengeForm.setFieldsValue({
      ...record,
    });
    showModal();
    // form.setFieldsValue({
    //   name: '',
    //   description: '',
    //   language: '',
    //   ...record,
    // });
    // setEditingKey(record.key);
  };

  const handleArchivePopCancel = () => {
    setArchivePopVisible(false);
  };

  const handleArchive = async (id) => {
    setArchiveConfirmLoading(true);
    try {
      const isArchived = challengeDataSelector.find(
        (v) => v._id === id
      ).archived;
      const response = await archiveChallenge(id, !isArchived);
      setArchiveConfirmLoading(false);
      setArchivePopVisible({ status: false, key: '', archived: null });
      if (response.status) {
        // onArchiveChallenge(id);
        message.success(isArchived ? 'Unarchived' : 'Archived');
        onUpdateChallenge(response.data?.key, response.data);
      } else {
        throw Error;
      }
    } catch (error) {
      setArchivePopVisible({ status: false, key: '', archived: null });
      message.error('Cannot archived');
      console.log('archiveHandle error', error);
      setArchiveConfirmLoading(false);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, row) => {
    try {
      // const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        onUpdateChallenge(key, row);
        console.log('old', item);
        console.log('new', newData[index]);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '40%',
      editable: true,
    },
    {
      title: 'Default Language',
      dataIndex: 'language',
      width: '20%',
      editable: true,
      render: (_, record) => {
        return (
          <p className="defaultlanguage">
            {record.translation && record.language}
          </p>
        );
      },
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          <p>
            {record.translation &&
              record.translation.map((item) => {
                return (
                  <Tooltip
                    placement="left"
                    title={item.name + ': ' + item.description}>
                    <p className="language">{item.language}</p>
                  </Tooltip>
                );
              })}
          </p>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          record.createdBy && (
            <Tooltip
              placement="left"
              title={moment(record.createdAt)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')}>
              <p className="language">{record.createdBy.username}</p>
            </Tooltip>
          )
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      width: '8%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to save?"
              onConfirm={() => save(record.key)}>
              <SaveTwoTone
                style={{ fontSize: '16px', marginRight: 20 }}
                twoToneColor="#52c41a"
              />
            </Popconfirm>
            <br />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a style={{ fontSize: '13px' }}>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}>
              <EditTwoTone
                style={{ fontSize: '16px', marginRight: 20 }}
                className="site-form-item-icon"
              />
            </Typography.Link>
            {record.archived ? (
              <Popconfirm
                title={'Sure to unarchive?'}
                visible={
                  archivePopVisible.key === record.key &&
                  archivePopVisible.status &&
                  archivePopVisible.archived === record.archived
                }
                onCancel={handleArchivePopCancel}
                okButtonProps={{ loading: archiveConfirmLoading }}
                onConfirm={() => handleArchive(record._id)}>
                <CheckCircleTwoTone
                  onClick={() =>
                    setArchivePopVisible({
                      status: true,
                      key: record.key,
                      archived: record.archived,
                    })
                  }
                  style={{ fontSize: '16px' }}
                  twoToneColor="#52c41a"
                />
              </Popconfirm>
            ) : (
              <Popconfirm
                title={'Sure to archive?'}
                visible={
                  archivePopVisible.key === record.key &&
                  archivePopVisible.status &&
                  archivePopVisible.archived === record.archived
                }
                onCancel={handleArchivePopCancel}
                okButtonProps={{ loading: archiveConfirmLoading }}
                onConfirm={() => handleArchive(record._id)}>
                <FileExclamationTwoTone
                  onClick={() =>
                    setArchivePopVisible({
                      status: true,
                      key: record.key,
                      archived: record.archived,
                    })
                  }
                  style={{ fontSize: '16px' }}
                  twoToneColor="#faad14"
                />
              </Popconfirm>
            )}
          </span>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'archive',
      align: 'center',
      render: (_, record) => {
        return !!record.archived ? (
          <Tooltip placement="left" title="Archived">
            <MinusCircleTwoTone
              twoToneColor="#faad14"
              style={{ fontSize: '19px' }}
            />
          </Tooltip>
        ) : (
          <Tooltip placement="left" title="Normal">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: '19px' }}
            />
          </Tooltip>
        );
      },
      filters: [
        {
          text: 'Normal',
          value: false,
        },
        {
          text: 'Archived',
          value: true,
        },
      ],
      onFilter: (value, record) => !!record.archived === value,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = ({
    _id,
    key,
    name,
    description,
    language,
    translation,
  }) => {
    let cloneData = data;
    const localUser = localStorage.getItem('user');
    const newData = {
      _id,
      key,
      name,
      description,
      language,
      translation,
      createdBy: JSON.parse(localUser),
    };
    onPushChallenge(newData);
    cloneData = [newData, ...cloneData];
    setData(cloneData);
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={12}>
          <h1>Challenge List ({(data && data.length) || 0})</h1>
        </Col>
        <Col span={5}>
          <Button
            onClick={showModal}
            type="primary"
            style={{
              float: 'right',
              marginBottom: 16,
            }}>
            Create New Challenge
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={challengeDataSelector}
              columns={mergedColumns}
              rowClassName="editable-row"
              loading={challengeLoadingSelector}
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </Col>
      </Row>
      <Modal
        title="Create A Challenge"
        visible={visible}
        onOk={handleSave}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={confirmLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleSave}>
            Submit
          </Button>,
          <Popconfirm
            title="Sure to clear form inputs?"
            onConfirm={handleClear}>
            <Button
              key="link"
              style={{ float: 'left' }}
              type="link"
              disabled={confirmLoading}>
              Reset Form
            </Button>
          </Popconfirm>,
        ]}>
        <CreateChallengeForm
          form={challengeForm}
          onFinish={handleSave}
          onFinishFailed={onFinishFailed}
        />
        <Divider />
        <Tooltip
          title="When the fury mode on this popup window will not be closed after creating a challenge."
          placement="topLeft">
          <p
            style={{ color: 'darkred', fontWeight: '500' }}
            hidden={updateMode}>
            <Switch defaultChecked={furyMode} onChange={onChangeFuryMode} />
            Fury Mode 😡
          </p>
        </Tooltip>
      </Modal>
    </>
  );
};

export default EditableTable;
