import React from 'react';
import logo from '../../images/dashboard.png';

const Logo = () => {
  return (
    <h1>
      <a href="/dashboard" id="logo">
        <img src={logo} className="logo" alt="logo" />
        <div className="df-beta">
          <div className="df-beta-text">Beta</div>
        </div>
      </a>
    </h1>
  );
};

export default Logo;
