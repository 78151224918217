// a little function to help us with reordering the result
import { message } from 'antd';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;
export const reorderQuoteMapNormal = ({ quoteMap, source, destination }) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    return {
      quoteMap: result,
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  return {
    quoteMap: result,
  };
};
export const reorderQuoteMapClone = ({ quoteMap, source, destination }) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    return {
      quoteMap: result,
    };
  }

  // moving to different list

  // remove from original
  // current.splice(source.index, 1); // TODO:
  // insert into next
  const item = next.filter((x) => x.content === target.content);
  console.log('thishit', item);
  if (item.length < 1) {
    let copy = target;
    copy.id = Math.random().toString();
    console.log(target);
    next.splice(destination.index, 0, copy);

    const result = {
      ...quoteMap,
      [destination.droppableId]: next,
    };

    return {
      quoteMap: result,
    };
  } else {
    message.warn('There is same habit already');
    const result = {
      ...quoteMap,
      // [source.droppableId]: reordered,
    };
    return {
      quoteMap: result,
    };
  }
};
