import React, { useEffect, useState } from 'react';
import { Line, Pie, Column } from '@ant-design/charts';
import { Card, Col, Row } from 'antd';
import Api from '../../../utils/api';

const App = () => {
  const [loading, setLoading] = React.useState(true);
  const [habitCountData, setHabitCountData] = useState(0);
  const [habitSummaryData, setHabitSummaryData] = useState([]);
  const [habitTypesData, setHabitTypesData] = useState([]);
  const [messageTypesData, setMessageTypesData] = useState([]);
  const [habitUserData, setHabitUserData] = useState([]);

  const getSummary = async () => Api.get('/statistics');

  useEffect(async () => {
    const summary = await getSummary();
    setHabitCountData(summary.data.habitCount);
    setHabitSummaryData(summary.data.dailyHabitCount);
    setHabitTypesData(summary.data.habitTypesSummary);
    setMessageTypesData(summary.data.messagesSummary);
    setHabitUserData(summary.data.habitUsers);
    setLoading(false);
  }, []);

  const habitCountConfig = {
    data: habitSummaryData,
    height: 400,
    xField: '_id',
    yField: 'Count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  };

  const habitTypesConfig = {
    appendPadding: 10,
    data: habitTypesData,
    angleField: 'value',
    colorField: 'label',
    radius: 0.9,
    label: {
      type: 'outer',
      content: '{name} {percentage} ({value})',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  const messageTypesConfig = {
    appendPadding: 10,
    data: messageTypesData,
    angleField: 'value',
    colorField: 'label',
    radius: 0.9,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  const habitUserConfig = {
    data: habitUserData,
    xField: 'username',
    yField: 'Count',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: { alias: '类别' },
      value: { alias: 'Count' },
    },
  };

  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col span={8}>
          <Card
            loading={loading}
            title="Habit Counts"
            extra={`Total:${habitCountData}`}
            bordered={false}>
            <Line {...habitCountConfig} />
          </Card>
        </Col>
        <Col span={8}>
          <Card loading={loading} title="Habit Types" bordered={false}>
            <Pie {...habitTypesConfig} />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            loading={loading}
            title="Habit Counts By Users"
            bordered={false}>
            <Column {...habitUserConfig} />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col span={8}>
          <Card loading={loading} title="Habit Message Types" bordered={false}>
            <Pie {...messageTypesConfig} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default App;
