import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHabit = (state) => state.Habit || initialState;

const selectHabitData = createSelector(selectHabit, (Habit) => Habit.data);
const selectHabitLoading = createSelector(
  selectHabit,
  (Habit) => Habit.loading
);
const selectHabitError = createSelector(selectHabit, (Habit) => Habit.error);

export { selectHabitData, selectHabitLoading, selectHabitError };
