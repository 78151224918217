import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Spin, message } from 'antd';

import { makeFetchRoutineHabits } from './actions';

import {
  selectRoutineHabitsRoutinesData,
  selectRoutineHabitsHabitsData,
  selectRoutineHabitsLoading,
  selectRoutineHabitsError,
} from './selectors';

import Api from '../../../utils/api';

import DND from './index2';

const App = () => {
  const dispatch = useDispatch();
  const [saveButtonLoading, setSaveButtonLoading] = React.useState(false);
  const [changedThings, setChangedThings] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const routineHabitsHabitsDataSelector = useSelector(
    selectRoutineHabitsHabitsData
  );
  const routineHabitsRoutinesDataSelector = useSelector(
    selectRoutineHabitsRoutinesData
  );
  const routineHabitsLoadingSelector = useSelector(selectRoutineHabitsLoading);
  const onFetchRoutineHabits = useCallback(
    () => dispatch(makeFetchRoutineHabits()),
    [dispatch]
  );

  const saveRoutineBuilder = async (data) =>
    Api.post('/routines/builder', data);

  useEffect(() => {
    onFetchRoutineHabits();
  }, []);

  const saveBuilder = async (data) => {
    try {
      const data2 = data;
      console.log('data', data2);
      Object.keys(data2)
        .filter(
          (item) => item !== 'ITEMS' && item !== 'ROUTINES' && item !== 'query'
        )
        .forEach((key) => {
          data2[key].forEach((x) => {
            x.updatedBy = [];
            x.icon = {
              filename: x.icon?.filename || '',
            };
          });
        });

      delete data2['ITEMS'];
      console.log('gidenb', data2);
      const response = await saveRoutineBuilder(data2);
      if (response.data.error.length > 0) {
        message.error('Error Occured');
      } else {
        message.success('Changes Saved');
        setChangedThings(false);
        console.log(response.data.success);
      }
      setSaveButtonLoading(false);
    } catch (err) {
      console.log('SAVE_BULDER_ERROR', err);
    }
  };

  const action = (type, actions) => {
    console.log(type, actions);
    switch (type) {
      case 'SAVE_ALL':
        setSaveButtonLoading(true);
        saveBuilder(actions);
        return;
      default:
        return;
    }
  };

  const changes = (value) => {
    setChangedThings(value);
  };

  const changeQuery = (e) => {
    setQuery(e.target.value);
  };

  return (
    <DND
      ROUTINES={routineHabitsRoutinesDataSelector}
      ITEMS={routineHabitsHabitsDataSelector}
      action={action}
      saveButtonLoading={saveButtonLoading}
      loading={routineHabitsLoadingSelector}
      changes={changes}
      changeQuery={changeQuery}
      query={query}
      changedThings={changedThings}
    />
  );
};

export default App;
