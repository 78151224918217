import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectChallengeHabits = (state) => state.ChallengeHabits || initialState;

const selectChallengeHabitsHabitsData = createSelector(
  selectChallengeHabits,
  (ChallengeHabits) => ChallengeHabits.habits
);

const selectChallengeHabitsChallengesData = createSelector(
  selectChallengeHabits,
  (ChallengeHabits) => ChallengeHabits.challenges
);

const selectChallengeHabitsLoading = createSelector(
  selectChallengeHabits,
  (ChallengeHabits) => ChallengeHabits.loading
);
const selectChallengeHabitsError = createSelector(
  selectChallengeHabits,
  (ChallengeHabits) => ChallengeHabits.error
);

export {
  selectChallengeHabitsHabitsData,
  selectChallengeHabitsChallengesData,
  selectChallengeHabitsLoading,
  selectChallengeHabitsError,
};
