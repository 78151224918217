import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectJourneyHabits = (state) => state.JourneyHabits || initialState;

const selectJourneyHabitsHabitsData = createSelector(
  selectJourneyHabits,
  (JourneyHabits) => JourneyHabits.habits
);

const selectJourneyHabitsJourneysData = createSelector(
  selectJourneyHabits,
  (JourneyHabits) => JourneyHabits.journeys
);

const selectJourneyHabitsLoading = createSelector(
  selectJourneyHabits,
  (JourneyHabits) => JourneyHabits.loading
);
const selectJourneyHabitsError = createSelector(
  selectJourneyHabits,
  (JourneyHabits) => JourneyHabits.error
);

export {
  selectJourneyHabitsHabitsData,
  selectJourneyHabitsJourneysData,
  selectJourneyHabitsLoading,
  selectJourneyHabitsError,
};
