import React, { useEffect } from 'react';
import logo from '../../images/logo2.png';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import './landing.css';

const ButtonWrapper = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 32px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  /* background: rgb(244, 244, 245); */
  border-width: 2px;
  border-style: solid;
  border-color: #ffc146;
  border-radius: 4px;
  background: #ffffff94;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  padding: 8px;
  margin: 0px;
  color: #ffc146;
  font-size: 13px;
  line-height: 24px;
  text-decoration: capitilize;

  &:hover {
    color: #fff;
    background: #ffc146;
  }

  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

function App() {
  const [dashboardVisible, setDashboardVisible] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  useEffect(() => {
    let user = localStorage.getItem('user');
    let accessToken = localStorage.getItem('token');
    user = JSON.parse(user);
    if (user && accessToken) {
      setUsername(user.username);
      setDashboardVisible(true);
    }
  });
  return (
    <div className="App">
      <header className="App-header5">
        <div
          style={{
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            marginTop: 100,
          }}>
          <div
            style={{
              backgroundColor: '#ffffff90',
              width: '90%',
              backgroundColor: '#fff',
              borderRadius: '10px',
              margin: 'auto',
              marginBottom: '10%',
              color: '#000',
            }}>
            <div className="container">
              <h1>Mindrobe Privacy Policy</h1>
              <p>
                Welcome to Mindrobe, your simple note-taking companion. At
                Mindrobe, we respect your privacy, and our approach is
                straightforward. Please read our Privacy Policy to understand
                how we handle your data.
              </p>
              <h2>Data Collection and Usage</h2>
              <p>
                Mindrobe is designed as an offline note-taking app, and it
                doesn't require user registration. We do not collect any
                personal information or use third-party services for tracking.
                Your notes are stored locally on your device, and we do not have
                access to them.
              </p>
              <h2>Security</h2>
              <p>
                Your data security is our priority. Mindrobe employs standard
                measures to protect your notes from unauthorized access,
                ensuring a safe and private note-taking experience.
              </p>
              <h2>Changes to Privacy Policy</h2>
              <p>
                Any updates to our Privacy Policy will be communicated through
                the app or posted on our website. We encourage you to review
                this Privacy Policy periodically.
              </p>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{' '}
                <a href="mailto:info@dayful.app">info@dayful.app</a>.
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
