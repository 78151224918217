import React, { useEffect } from 'react';
import { Button, Modal, Form, Select, Input, Space, Radio } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;
const languages = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'German', value: 'german' },
  { label: 'French', value: 'french' },
  { label: 'Turkish', value: 'turkish' },
];

const createJourney = ({ form, onFinish, onFinishFailed }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={{ language: 'english' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item name="_id" label="_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="key" label="Key" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name of Journey"
        rules={[
          { required: true, message: 'Please input the name of journey!' },
        ]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description of Journey">
        <Input type="textarea" />
      </Form.Item>
      <Form.Item name="language" label="Default Language">
        <Select placeholder="Select a language" disabled>
          {(languages || []).map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {/* <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.language !== currentValues.language}
        >
            {({ getFieldValue }) =>
                getFieldValue('language') === 'other' ? (
                    <Form.Item name="customizelanguage" label="Type Language" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                ) : null
            }
        </Form.Item> */}

      <Form.List name="translation">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Space key={field.key} align="center">
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.area !== curValues.area ||
                    prevValues.translation !== curValues.translation
                  }>
                  {() => (
                    <Form.Item
                      {...field}
                      label="Language"
                      name={[field.name, 'language']}
                      fieldKey={[field.fieldKey, 'language']}
                      rules={[{ required: true, message: 'Missing language' }]}>
                      <Select
                        disabled={!form.getFieldValue('language')}
                        style={{ width: 130 }}>
                        {(
                          languages.filter(
                            (e) => e.value !== form.getFieldValue('language')
                          ) || []
                        ).map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item
                  {...field}
                  label="Name"
                  name={[field.name, 'name']}
                  fieldKey={[field.fieldKey, 'name']}
                  rules={[{ required: true, message: 'Missing name' }]}>
                  <Input />
                </Form.Item>

                <Form.Item
                  {...field}
                  label="Description"
                  name={[field.name, 'description']}
                  fieldKey={[field.fieldKey, 'description']}
                  rules={[{ required: true, message: 'Missing description' }]}>
                  <Input />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Add Translation
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default createJourney;
