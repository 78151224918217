import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import './index.css';
import {
  Table,
  Tag,
  Input,
  InputNumber,
  Popconfirm,
  Tooltip,
  Form,
  Typography,
  Row,
  Col,
  Button,
  Modal,
  message,
  Divider,
  Switch,
} from 'antd';
import {
  EditOutlined,
  EditTwoTone,
  SaveTwoTone,
  DeleteTwoTone,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
  FileExclamationTwoTone,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  makeFetchRoutines,
  makePushRoutine,
  makeUpdateRoutine,
  makeArchiveRoutine,
} from './actions';
import {
  selectRoutineData,
  selectRoutineLoading,
  selectRoutineError,
} from './selectors';
import CreateRoutineForm from './form/createRoutine';
import EditBackgroundForm from './form/editBackgroundForm';
import Api from '../../../utils/api';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = () => {
  const [form] = Form.useForm();
  const [routineForm] = Form.useForm();
  const [backgroundForm] = Form.useForm();
  const [data, setData] = useState([]);
  const [furyMode, setFuryMode] = useState(true);
  const [updateMode, setUpdateMode] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [selectedBackgroundKey, setSelectedBackgroundKey] = useState('');
  const [visible, setVisible] = React.useState(false);
  const [backgroundVisible, setBackgroundVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [archiveConfirmLoading, setArchiveConfirmLoading] = useState(false);
  const [archivePopVisible, setArchivePopVisible] = useState(false);
  const [modalText, setModalText] = React.useState('Content of the modal');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const routineDataSelector = useSelector(selectRoutineData);
  const routineLoadingSelector = useSelector(selectRoutineLoading);
  const routineErrorSelector = useSelector(selectRoutineError);
  const onFetchRoutine = useCallback(
    () => dispatch(makeFetchRoutines()),
    [dispatch]
  );
  const onPushRoutine = useCallback(
    (data) => dispatch(makePushRoutine(data)),
    [dispatch]
  );
  const onUpdateRoutine = useCallback(
    (key, data) => dispatch(makeUpdateRoutine(key, data)),
    [dispatch]
  );
  const onArchiveRoutine = useCallback(
    (id) => dispatch(makeArchiveRoutine(id)),
    [dispatch]
  );

  useEffect(() => {
    setLoading(true);
    onFetchRoutine();
  }, []);

  useEffect(() => {
    if (
      Array.isArray(routineDataSelector) &&
      routineDataSelector.length >= 0 &&
      loading
    ) {
      setData(routineDataSelector);
    }
  }, [routineDataSelector]);

  useEffect(() => {
    if (routineErrorSelector) {
      // console.log('SELECTOR_ERROR', routineErrorSelector);
      message.error(routineErrorSelector || 'Error occured');
      setLoading(false);
    }
  }, [routineErrorSelector]);

  const showModal = () => {
    const furymode = localStorage.getItem('furymode');
    setFuryMode(furymode ? Boolean(JSON.stringify(furymode)) : true);
    setVisible(true);
  };

  const showBackgroundModal = () => {
    setBackgroundVisible(true);
  };

  const createRoutineAction = async (values) => {
    const { name, description, language, translation, icon, font } = values;
    if (name) {
      try {
        const res = await createRoutine({
          name,
          description,
          language,
          translation,
          icon,
          font,
        });
        message.success('Routine created');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish', err);
        throw new Error(err);
      }
    }
  };

  const createRoutine = async (data) => Api.post('/routines', data);

  const updateRoutine = async (data) => Api.put('/routines', data);
  const updateBackground = async (data) =>
    Api.put('/routines/background', data);

  const archiveRoutine = async (id, status) =>
    Api.put('/routines/' + id + '/archive/' + status);

  const updateRoutineAction = async (values) => {
    const {
      _id,
      key,
      name,
      description,
      language,
      translation,
      icon,
      font,
      images,
    } = values;
    console.log('key', {
      key,
      _id,
    });
    if (_id && key) {
      try {
        const res = await updateRoutine({
          _id,
          key,
          name,
          description,
          language,
          translation,
          icon,
          font,
          images,
        });
        message.success('Routine Updated');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish updated', err);
        throw new Error(err);
      }
    } else {
      message.error('Bir hata oluştu code:3');
    }
  };

  const updateBackgroundAction = async (key, backgroundKey) => {
    if (key && backgroundKey) {
      try {
        const res = await updateBackground({
          key,
          backgroundKey,
        });
        message.success('Routine Background Updated');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish updated', err);
        throw new Error(err);
      }
    } else {
      message.error('Bir hata oluştu code:3');
    }
  };

  const onCreate = async (values) => {
    console.log('Received values of form: ', values);
    try {
      if (updateMode) {
        const response = await updateRoutineAction(values);
        if (response.status) {
          save(values.key, response.data);
        }
      } else {
        const { data } = await createRoutineAction(values);
        handleAdd(data);
      }
      if (!furyMode || updateMode) {
        setVisible(false);
      }
      routineForm.resetFields();
    } catch (err) {
      console.log("oncreate'de error", err);
    }
    setConfirmLoading(false);
  };

  const onEditBackground = async (key, backgroundKey) => {
    console.log('Received values of form: ', key, backgroundKey);
    try {
      const response = await updateBackgroundAction(key, backgroundKey);
      if (response.status) {
        save(key, response.data);
      }
      setBackgroundVisible(false);
      backgroundForm.resetFields();
    } catch (err) {
      console.log("edit background'de error", err);
    }
    setConfirmLoading(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // setModalText('The modal will be closed after two seconds');
    routineForm
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleSaveBackground = (e) => {
    e.preventDefault();
    backgroundForm
      .validateFields()
      .then((values) => {
        setConfirmLoading(true);
        console.log('#### SAVING 1', values.key, selectedBackgroundKey);
        onEditBackground(values.key, selectedBackgroundKey);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClear = () => {
    routineForm.resetFields();
  };

  const handleCancel = async () => {
    if (updateMode) {
      handleClear();
      console.log('UPDATE MODE WILL BE OFF');
      setUpdateMode(false);
    }
    console.log('Clicked cancel button');
    setVisible(false);
  };

  const handleCancelBackground = async () => {
    backgroundForm.resetFields();
    setBackgroundVisible(false);
  };

  const onChangeFuryMode = (data) => {
    localStorage.setItem('furymode', JSON.stringify(data));
    setFuryMode(data);
  };

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    console.log('data', record);
    setUpdateMode(true);
    routineForm.setFieldsValue({
      ...record,
    });
    showModal();
    // form.setFieldsValue({
    //   name: '',
    //   description: '',
    //   language: '',
    //   ...record,
    // });
    // setEditingKey(record.key);
  };

  const editBackground = (record) => {
    console.log('data', record);
    backgroundForm.setFieldsValue({
      ...record,
    });
    showBackgroundModal();
  };

  const handleArchivePopCancel = () => {
    setArchivePopVisible(false);
  };

  const handleArchive = async (id) => {
    setArchiveConfirmLoading(true);
    try {
      const isArchived = routineDataSelector.find((v) => v._id === id).archived;
      const response = await archiveRoutine(id, !isArchived);
      setArchiveConfirmLoading(false);
      setArchivePopVisible({ status: false, key: '', archived: null });
      if (response.status) {
        // onArchiveRoutine(id);
        message.success(isArchived ? 'Unarchived' : 'Archived');
        onUpdateRoutine(response.data?.key, response.data);
      } else {
        throw Error;
      }
    } catch (error) {
      setArchivePopVisible({ status: false, key: '', archived: null });
      message.error('Cannot archived');
      console.log('archiveHandle error', error);
      setArchiveConfirmLoading(false);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key, row) => {
    try {
      // const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setData(newData);
        onUpdateRoutine(key, row);
        console.log('old', item);
        console.log('new', newData[index]);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '25%',
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '40%',
      editable: true,
    },
    {
      title: 'Default Language',
      dataIndex: 'language',
      width: '20%',
      editable: true,
      render: (_, record) => {
        return (
          <p className="defaultlanguage">
            {record.translation && record.language}
          </p>
        );
      },
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          <p>
            {record.translation &&
              record.translation.map((item) => {
                return (
                  <Tooltip
                    placement="left"
                    title={item.name + ': ' + item.description}>
                    <p className="language">{item.language}</p>
                  </Tooltip>
                );
              })}
          </p>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'createdBy',
      width: '3%',
      editable: false,
      render: (_, record) => {
        return (
          record.createdBy && (
            <Tooltip
              placement="left"
              title={moment(record.createdAt)
                .local()
                .format('YYYY-MM-DD HH:mm:ss')}>
              <p className="language">{record.createdBy.username}</p>
            </Tooltip>
          )
        );
      },
    },
    {
      title: 'Background',
      dataIndex: 'images',
      width: '10%',
      editable: false,
      render: (_, record) => {
        const isDuplicate = routineDataSelector
          .filter((z) => z.key !== record.key)
          .some((v) => v.images?.small?.url === record?.images?.small?.url);
        return (
          <React.Fragment>
            <Tooltip title="Edit Routine">
              <Typography.Link
                disabled={editingKey !== ''}
                onClick={() => editBackground(record)}>
                <EditTwoTone
                  style={{ fontSize: '16px', marginRight: 20 }}
                  className="site-form-item-icon"
                />
              </Typography.Link>
            </Tooltip>
            {(record.images?.medium?.url || record.images?.small?.url) && (
              <React.Fragment>
                {isDuplicate && <Tag color={'red'}>Already used</Tag>}
                <img
                  src={record.images?.small?.url || record.images?.medium?.url}
                  alt="background"
                  width={56}
                />
                {!record.images.small?.url && 'Small Not Found'}
                {!record.images.medium?.url && 'Medium Not Found'}
                {!record.images.big?.url && 'Big Not Found'}
                {!record.images.banner?.url && 'Banner Not Found'}
                {!record.images.original?.url && 'Original Not Found'}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      align: 'center',
      width: '8%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Popconfirm
              title="Sure to save?"
              onConfirm={() => save(record.key)}>
              <SaveTwoTone
                style={{ fontSize: '16px', marginRight: 20 }}
                twoToneColor="#52c41a"
              />
            </Popconfirm>
            <br />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a style={{ fontSize: '13px' }}>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <span style={{ flexDirection: 'row' }}>
            <Tooltip title="Edit Routine">
              <Typography.Link
                disabled={editingKey !== ''}
                onClick={() => edit(record)}>
                <EditTwoTone
                  style={{ fontSize: '16px', marginRight: 20 }}
                  className="site-form-item-icon"
                />
              </Typography.Link>
            </Tooltip>
            {record.archived ? (
              <Popconfirm
                title={'Sure to unarchive?'}
                visible={
                  archivePopVisible.key === record.key &&
                  archivePopVisible.status &&
                  archivePopVisible.archived === record.archived
                }
                onCancel={handleArchivePopCancel}
                okButtonProps={{ loading: archiveConfirmLoading }}
                onConfirm={() => handleArchive(record._id)}>
                <CheckCircleTwoTone
                  onClick={() =>
                    setArchivePopVisible({
                      status: true,
                      key: record.key,
                      archived: record.archived,
                    })
                  }
                  style={{ fontSize: '16px' }}
                  twoToneColor="#52c41a"
                />
              </Popconfirm>
            ) : (
              <Popconfirm
                title={'Sure to archive?'}
                visible={
                  archivePopVisible.key === record.key &&
                  archivePopVisible.status &&
                  archivePopVisible.archived === record.archived
                }
                onCancel={handleArchivePopCancel}
                okButtonProps={{ loading: archiveConfirmLoading }}
                onConfirm={() => handleArchive(record._id)}>
                <FileExclamationTwoTone
                  onClick={() =>
                    setArchivePopVisible({
                      status: true,
                      key: record.key,
                      archived: record.archived,
                    })
                  }
                  style={{ fontSize: '16px' }}
                  twoToneColor="#faad14"
                />
              </Popconfirm>
            )}
          </span>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'archive',
      align: 'center',
      render: (_, record) => {
        return !!record.archived ? (
          <Tooltip placement="left" title="Archived">
            <MinusCircleTwoTone
              twoToneColor="#faad14"
              style={{ fontSize: '19px' }}
            />
          </Tooltip>
        ) : (
          <Tooltip placement="left" title="Normal">
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: '19px' }}
            />
          </Tooltip>
        );
      },
      filters: [
        {
          text: 'Normal',
          value: false,
        },
        {
          text: 'Archived',
          value: true,
        },
      ],
      onFilter: (value, record) => !!record.archived === value,
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = ({
    _id,
    key,
    name,
    description,
    language,
    translation,
  }) => {
    let cloneData = data;
    const localUser = localStorage.getItem('user');
    const newData = {
      _id,
      key,
      name,
      description,
      language,
      translation,
      createdBy: JSON.parse(localUser),
    };
    onPushRoutine(newData);
    cloneData = [newData, ...cloneData];
    setData(cloneData);
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Row justify="space-between">
        <Col span={12}>
          <h1>Routine Listss ({(data && data.length) || 0})</h1>
        </Col>
        <Col span={5}>
          <Button
            onClick={showModal}
            type="primary"
            style={{
              float: 'right',
              marginBottom: 16,
            }}>
            Create New Routine
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form form={form} component={false}>
            <Table
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={routineDataSelector}
              columns={mergedColumns}
              rowClassName="editable-row"
              loading={routineLoadingSelector}
              pagination={{
                onChange: cancel,
              }}
            />
          </Form>
        </Col>
      </Row>
      <Modal
        title="Create A Routine"
        visible={visible}
        onOk={handleSave}
        okText="Save"
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={confirmLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleSave}>
            Submit
          </Button>,
          <Popconfirm
            title="Sure to clear form inputs?"
            onConfirm={handleClear}>
            <Button
              key="link"
              style={{ float: 'left' }}
              type="link"
              disabled={confirmLoading}>
              Reset Form
            </Button>
          </Popconfirm>,
        ]}>
        <CreateRoutineForm
          form={routineForm}
          onFinish={handleSave}
          onFinishFailed={onFinishFailed}
        />
        <Divider />
        <Tooltip
          title="When the fury mode on this popup window will not be closed after creating a routine."
          placement="topLeft">
          <p
            style={{ color: 'darkred', fontWeight: '500' }}
            hidden={updateMode}>
            <Switch defaultChecked={furyMode} onChange={onChangeFuryMode} />
            Fury Mode 😡
          </p>
        </Tooltip>
      </Modal>
      <Modal
        title="Edit Background"
        visible={backgroundVisible}
        onOk={handleSaveBackground}
        okText="Save"
        width={1000}
        confirmLoading={confirmLoading}
        onCancel={handleCancelBackground}
        footer={[
          <Button
            key="back"
            onClick={handleCancelBackground}
            disabled={confirmLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleSaveBackground}>
            Submit
          </Button>,
          <Popconfirm
            title="Sure to clear form inputs?"
            onConfirm={handleClear}>
            <Button
              key="link"
              style={{ float: 'left' }}
              type="link"
              disabled={confirmLoading}>
              Reset Form
            </Button>
          </Popconfirm>,
        ]}>
        <EditBackgroundForm
          form={backgroundForm}
          onFinish={handleSaveBackground}
          onFinishFailed={onFinishFailed}
          setSelectedBackgroundKey={setSelectedBackgroundKey}
        />
      </Modal>
    </>
  );
};

export default EditableTable;
