import * as actions from './types';

export const initialState = { token: null };

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        token: action.token,
      };
    case actions.LOGIN_ERROR:
      return {
        token: action.token,
      };
    case actions.LOGOUT:
      return initialState;
    default:
      return state;
  }
}
