import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoutine = (state) => state.Routine || initialState;

const selectRoutineData = createSelector(
  selectRoutine,
  (Routine) => Routine.data
);
const selectRoutineLoading = createSelector(
  selectRoutine,
  (Routine) => Routine.loading
);
const selectRoutineError = createSelector(
  selectRoutine,
  (Routine) => Routine.error
);

export { selectRoutineData, selectRoutineLoading, selectRoutineError };
