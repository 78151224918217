import React from 'react';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';

const App = ({ onFinish, onFinishFailed }) => {
  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
      }}
      style={{ paddingTop: 15 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item
        name="passcode"
        rules={[{ required: true, message: 'Please input your OTP!' }]}>
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="OTP Passcode"
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          block>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default App;
