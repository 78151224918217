import React, { useEffect, useMemo } from 'react';
import logo from '../../images/logo2.png';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import './landing.css';
import { Link, useSearchParams } from 'react-router-dom';

const ButtonWrapper = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 32px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  /* background: rgb(244, 244, 245); */
  border-width: 2px;
  border-style: solid;
  border-color: #ffc146;
  border-radius: 4px;
  background: #ffffff94;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  padding: 8px;
  margin: 0px;
  color: #ffc146;
  font-size: 13px;
  line-height: 24px;
  text-decoration: capitilize;

  &:hover {
    color: #fff;
    background: #ffc146;
  }

  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isLight = useMemo(
    () => searchParams.get('light') === 'true',
    [searchParams]
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 0,
        padding: 0,
        height: '100vh',
        backgroundColor: isLight ? '#f2f2f6' : '#121212',
        color: isLight ? '#121212' : '#fff',
      }}>
      Available soon!
      {/* {['How to create routine', 'How to create habit'].map((item, index) => (
        // card 10 radius lighter than background 20 px margin width
        <Link to={`/privacy`}
          key={index}
          style=
          {{
            marginTop: '15px',
            width: 'calc(100% - 40px)',
            borderRadius: '20px',
            backgroundColor: !isLight ? '#1D1D1D' : '#fff',
            color: !isLight ? '#fff' : '#121212',
            fontSize: '14px',
          }}
          >
          <div
            style={{
              padding: '15px 20px',
            }}>
            {item}
          </div>
        </Link>
      ))}
      Account & Subscription Guides */}
    </div>
  );
}

export default App;
