import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import uuid from 'uuid/v4';
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { Col, Row, Button, Alert, Tooltip, Input, Spin } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { QuestionCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import InnerHabitList from './innerHabitList';
import InnerKioskList from './InnerKioskList';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function moveItem(data, from, to) {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0];
  // insert stored item into position `to`
  data.splice(to, 0, f);
  return data;
}

/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const remove = (source, droppableSource, id) => {
  console.log('deleted', source, droppableSource);
  const sourceClone = Array.from(source);
  console.log('trest', sourceClone);
  sourceClone.splice(
    sourceClone.findIndex((x) => x.id === droppableSource),
    1
  );
  const result = {};
  result[id] = sourceClone;
  console.log(result);
  return result;
};

const grid = 8;

const Badge = styled.small`
  flex-grow: 0;
  margin: 0;
  background-color: ${colors.P50};
  border-radius: 4px;
  font-weight: normal;
  padding: 4px;
`;

const Content = styled.div`
  display: flex;
  margin-right: 250px;
  flex-wrap: wrap;
`;

const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: #fff;
  border: 1px ${(props) => (props.isDragging ? 'dashed #000' : 'solid #ddd')};
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  margin: -0.5rem 0.5rem -0.5rem -0.5rem;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  background: #fff;
  border-right: 1px solid #ddd;
  color: #000;
`;

const List = styled.div`
  border: 1px
    ${(props) => (props.isDraggingOver ? 'dashed #000' : 'solid #ddd')};
  background: #fafafa;
  padding: 0.5rem 0.5rem 0;
  border-radius: 3px;
  flex: 0 0 150px;
  font-family: sans-serif;
`;

const Kiosk = styled(List)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  overflow-y: scroll;
  background: #fafafa;
  z-index: 999999;
`;

const Container = styled(List)`
  display: flex;
  flex-direction: column;
  opacity: inherit;
  padding: 0px 0px 8px 0px;
  border: 1px solid #f0f0f0;
  transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
  user-select: none;
  min-width: 433px;
  border-radius: 5px;
  margin: 0.5rem 0.5rem 1.5rem;
  height: fit-content;
  background-color: ${({ isDraggingOver }) =>
    isDraggingOver ? 'rgb(234, 230, 255)' : ''};
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  padding: 11px 13px 0px 10px;
  background: #fafafa;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  transition: background 0.3s ease;
`;

const InnerContainer = styled.div`
  padding: 0px 20px;
`;
const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0.5rem;
  padding: 0.5rem;
  color: #000;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 3px;
  font-size: 1rem;
  cursor: pointer;
`;

const ButtonText = styled.div`
  margin: 0 1rem;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ITEMS !== this.props.ITEMS) {
      this.setState({ ITEMS: this.props.ITEMS });
    }
    if (prevProps.CHALLENGES !== this.props.CHALLENGES) {
      console.log(this.props.CHALLENGES.length);
      this.props.CHALLENGES.forEach((item, i) => {
        this.setState({ [item._id]: item.habits || [] });
        console.log('creating', i);
      });
    }
  }

  saveAll = () => {
    this.props.action('SAVE_ALL', this.state);
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        this.setState(
          {
            [destination.droppableId]: reorder(
              this.state[source.droppableId],
              source.index,
              destination.index
            ),
          },
          () => {
            console.log('REORDER', this.state[source.droppableId]);
            this.props.changes(true);
          }
        );
        break;
      case 'ITEMS':
        this.setState(
          {
            [destination.droppableId]: copy(
              this.props.query
                ? this.props.ITEMS.filter((o) =>
                    Object.keys(o).some((k) =>
                      JSON.stringify(o[k])
                        .toLowerCase()
                        .includes(this.props.query.toLowerCase())
                    )
                  )
                : this.props.ITEMS,
              this.state[destination.droppableId],
              source,
              destination
            ),
          },
          () => {
            this.props.action('ITEMS_COPY', {
              destinationItem: this.state[destination.droppableId],
              source,
              destination,
            });
            console.log('ITEMS_COPY', this.state[destination.droppableId]);
            this.props.changes(true);
          }
        );
        break;
      default:
        this.setState(
          move(
            this.state[source.droppableId],
            this.state[destination.droppableId],
            source,
            destination
          ),
          () => {
            console.log('MOVE_DEFAULT_SOURCE', this.state[source.droppableId]);
            console.log(
              'MOVE_DEFAULT_DESTINATION',
              this.state[destination.droppableId]
            );
            this.props.changes(true);
          }
        );
        break;
    }
  };

  addList = (e) => {
    this.setState({ [uuid()]: [] });
  };

  removeItem = (list, source) => {
    this.setState(remove(this.state[list], source, list), () => {
      console.log('REMOVE_SOURCE', this.state);
      this.props.changes(true);
    });
  };

  handleSearch = (e) => {
    this.props.changeQuery(e);
  };
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <Spin spinning={this.props.loading || this.props.saveButtonLoading}>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="ITEMS" isDropDisabled={true}>
            {(provided, snapshot) => (
              <Kiosk
                innerRef={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}>
                <Input
                  style={{ position: 'sticky', top: 0, right: 0 }}
                  autoFocus
                  allowClear
                  onChange={this.handleSearch}
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                />
                <div style={{ height: 15 }}></div>
                <InnerKioskList
                  items={
                    this.props.query
                      ? this.props.ITEMS.filter((o) =>
                          Object.keys(o).some((k) =>
                            JSON.stringify(o[k])
                              .toLowerCase()
                              .includes(this.props.query.toLowerCase())
                          )
                        )
                      : this.props.ITEMS
                  }
                />
              </Kiosk>
            )}
          </Droppable>
          <Row justify="space-between" style={{ marginRight: 299 }}>
            <Col span={6}>
              <h1>Challenge Builder</h1>
            </Col>

            <Col span={5}>
              <Tooltip
                style={{ fontSize: 11 }}
                title="Unsaved changes"
                color={'orange'}
                key={'orange'}
                visible={this.props.changedThings}>
                <Button
                  loading={this.props.saveButtonLoading}
                  disabled={
                    this.props.loading ||
                    !(
                      Array.isArray(this.props.CHALLENGES) &&
                      this.props.CHALLENGES.length > 0
                    )
                  }
                  onClick={() => {
                    this.saveAll();
                  }}
                  type="primary"
                  style={{
                    float: 'right',
                    marginBottom: 16,
                  }}>
                  Save Changes
                </Button>
              </Tooltip>
            </Col>
          </Row>
          {/* <Button onClick={this.addList}>
          <ButtonText>Sort Mode</ButtonText>
        </Button> */}
          <Content>
            {Object.keys(this.state)
              .filter(
                (item) =>
                  item !== 'ITEMS' && item !== 'CHALLENGES' && item !== 'query'
              )
              .map((list, i) => (
                <Droppable key={list} droppableId={list}>
                  {(provided, snapshot) => (
                    <Container
                      innerRef={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}>
                      <Header
                        isDraggingOver={snapshot.isDraggingOver}
                        onClick={() => console.log(this.state[list])}>
                        <p className="df-order">
                          {this.props.CHALLENGES.findIndex(
                            (v) => v._id === list
                          ) + 1}
                        </p>
                        <p style={{ display: 'flex', alignItems: 'center' }}>
                          {
                            this.props.CHALLENGES.find((v) => v._id === list)
                              .name
                          }
                          {!!this.props.CHALLENGES.find((v) => v._id === list)
                            .archived && (
                            <Tooltip
                              placement="left"
                              title="This Challenge archived but users still accessing this, please be careful when making action on this.">
                              <QuestionCircleTwoTone
                                twoToneColor="#faad14"
                                style={{ fontSize: '19px', marginLeft: 5 }}
                              />
                            </Tooltip>
                          )}
                        </p>
                        <p style={{ float: 'right' }}>
                          <Badge>
                            {Math.floor(
                              this.state[list].reduce(
                                (total, obj) => obj.time + total,
                                0
                              ) / 60
                            )}{' '}
                            m
                          </Badge>
                        </p>
                      </Header>
                      <InnerContainer>
                        <InnerHabitList
                          items={this.state[list]}
                          removeItem={(id) => this.removeItem(list, id)}
                        />
                      </InnerContainer>
                      {provided.placeholder}
                    </Container>
                  )}
                </Droppable>
              ))}
          </Content>
        </DragDropContext>
      </Spin>
    );
  }
}

// Put the things into the DOM!
export default App;
