import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  Form,
  Select,
  Input,
  Space,
  Switch,
  InputNumber,
  Radio,
  Row,
  Col,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  FlagTwoTone,
  StarTwoTone,
  TrophyTwoTone,
} from '@ant-design/icons';
import IconList from '../../Icon/icontab';
const { Option } = Select;
const languages = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'German', value: 'german' },
  { label: 'French', value: 'french' },
  { label: 'Turkish', value: 'turkish' },
];

const categories = [
  { label: 'Action', value: 'action' },
  { label: 'Motivation', value: 'motivation' },
];

const themedCategories = [
  'creativity',
  'investment',
  'consumption',
  'purpose',
  'order',
  'recharging',
  'relationships',
  'productivity',
  'energy',
  'health',
  'growth',
  'happiness',
  'mindfulness',
  'spirituality',
  'wellbeing',
  'work',
  'leisure',
  'family',
  'friends',
  'romance',
  'self',
  'career',
  'finance',
];

const createHabit = ({
  form,
  onFinish,
  onFinishFailed,
  setValuesForm,
  setSelectedIcon,
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      onValuesChange={(values) => setValuesForm(values)}
      initialValues={{
        language: 'english',
        types: ['journey', 'routine', 'challenge'],
        categories: [],
        time: 0,
        category: 'description',
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}>
      <Form.Item name="_id" label="_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="key" label="Key" hidden>
        <Input />
      </Form.Item>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name of Habit"
            rules={[
              { required: true, message: 'Please input the name of habit!' },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description of Habit">
            <Input type="textarea" />
          </Form.Item>
          <Form.Item name="types" label="Type">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select">
              <Option key="journey" value="journey" label="Journey">
                <FlagTwoTone twoToneColor="#059185" /> Journey
              </Option>
              <Option key="routine" value="routine" label="Routine">
                <StarTwoTone twoToneColor="#E68372" /> Routine
              </Option>
              <Option key="challenge" value="challenge" label="Challenge">
                <TrophyTwoTone twoToneColor="#4F57CE" /> Challenge
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="categories" label="Categories">
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select">
                {themedCategories.map((item) => (
                  <Option key={item} value={item} label={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="time"
            label="How Much It Takes Time"
            extra="In Seconds(ex: 120 => 2 min)"
            rules={[{ required: true, message: 'Please enter a number' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name="language" label="Default Language" hidden>
            <Select placeholder="Select a language" disabled>
              {(languages || []).map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          Icon
          <div style={{ marginBottom: 5 }}></div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => prevValues !== curValues}>
            {(form) => {
              return (
                <IconList
                  form={form}
                  onClickedIcon={(e) => {
                    setSelectedIcon(e);
                    form.setFieldsValue({
                      icon: e,
                    });
                  }}
                />
              );
            }}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.List name="translation">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="center">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.translation !== curValues.translation
                      }>
                      {() => (
                        <Form.Item
                          {...field}
                          label="Language"
                          name={[field.name, 'language']}
                          fieldKey={[field.fieldKey, 'language']}
                          rules={[
                            { required: true, message: 'Missing language' },
                          ]}>
                          <Select
                            disabled={!form.getFieldValue('language')}
                            style={{ width: 130 }}>
                            {(
                              languages.filter(
                                (e) =>
                                  e.value !== form.getFieldValue('language')
                              ) || []
                            ).map((item) => (
                              <Option key={item.value} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Name"
                      name={[field.name, 'name']}
                      fieldKey={[field.fieldKey, 'name']}
                      rules={[{ required: true, message: 'Missing name' }]}>
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      label="Description"
                      name={[field.name, 'description']}
                      fieldKey={[field.fieldKey, 'description']}
                      rules={[
                        { required: true, message: 'Missing description' },
                      ]}>
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add Translation
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
        <Col span={12}>
          <Form.List name="messages">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="start">
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues.area !== curValues.area ||
                        prevValues.messages !== curValues.messages
                      }>
                      {() => (
                        <Form.Item
                          {...field}
                          label="Category"
                          name={[field.name, 'category']}
                          fieldKey={[field.fieldKey, 'category']}
                          rules={[
                            { required: true, message: 'Missing category' },
                          ]}>
                          <Select
                            disabled={!form.getFieldValue('category')}
                            style={{ width: 130 }}>
                            {(
                              categories.filter(
                                (e) =>
                                  e.value !== form.getFieldValue('category')
                              ) || []
                            ).map((item) => (
                              <Option key={item.value} value={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Text"
                      name={[field.name, 'text']}
                      fieldKey={[field.fieldKey, 'text']}
                      rules={[{ required: true, message: 'Missing text' }]}>
                      <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label="Extra"
                      name={[field.name, 'extra']}
                      fieldKey={[field.fieldKey, 'extra']}>
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(field.name)}
                      style={{ marginTop: '38px' }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}>
                    Add Help Message
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>

      {/* <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.language !== currentValues.language}
        >
            {({ getFieldValue }) =>
                getFieldValue('language') === 'other' ? (
                    <Form.Item name="customizelanguage" label="Type Language" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                ) : null
            }
        </Form.Item> */}
    </Form>
  );
};

export default createHabit;
