import React, { useEffect } from 'react';
import './landing.css';

function App() {
  return (
    <div className="App">
      <header className="App-header5">
        <div
          style={{
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            marginTop: 100,
          }}>
          <div
            style={{
              backgroundColor: '#ffffff90',
              width: '90%',
              backgroundColor: '#fff',
              borderRadius: '10px',
              margin: 'auto',
              marginBottom: '10%',
              color: '#000',
            }}>
            <div className="container">
              <h1>Mindrobe Terms of Service</h1>

              <h3>Effective Date: March 1, 2024</h3>

              <p>
                Welcome to Mindrobe! These Terms of Service ("Terms") govern
                your use of the Mindrobe mobile application ("the App") and any
                related services provided by Mindrobe ("we," "us," or "our"). By
                accessing or using the App, you agree to be bound by these
                Terms. If you do not agree with these Terms, please refrain from
                using the App.
              </p>

              <p>
                By using our note-taking app, you agree to the following terms.
                Please read them carefully.
              </p>

              <h3>Data Usage</h3>
              <p>
                Mindrobe is an offline note-taking app. We do not collect any
                data from your device. Your notes are stored locally on your
                device only.
              </p>

              <h3>User Responsibility</h3>
              <p>
                You are responsible for managing and backing up your notes.
                Mindrobe is not liable for any loss of data.
              </p>

              <h3>Acceptable Use</h3>
              <p>
                Use Mindrobe for lawful and personal purposes. Do not use the
                app to infringe on the rights of others.
              </p>

              <h3>Limitation of Liability</h3>
              <p>
                Mindrobe is provided "as is" without any warranties. We are not
                liable for any damages or losses incurred through the use of the
                app.
              </p>

              <h3>Changes</h3>
              <p>
                Mindrobe reserves the right to change and update these Terms of
                Service. We also reserve the right to make price changes with a
                minimum of 30 days' notice. You will be notified of significant
                changes by email.
              </p>

              <h3>Acknowledgement</h3>
              <p>
                You and the End-User acknowledge that this End-User License
                Agreement ("EULA") is concluded between you (the developer) and
                the End-User, and not with Apple Inc. ("Apple"). You acknowledge
                that you are solely responsible for the Licensed Application and
                its content. The EULA shall not provide usage rules for Licensed
                Applications that conflict with the Apple Media Services Terms
                and Conditions as of the Effective Date, which you acknowledge
                you have had the opportunity to review.
              </p>

              <h3>Scope of License</h3>
              <p>
                The license granted to the End-User for the Licensed Application
                is limited to a non-transferable license to use the Licensed
                Application on any Apple-branded Products that the End-User owns
                or controls, in accordance with the Usage Rules set forth in the
                Apple Media Services Terms and Conditions. However, the Licensed
                Application may be accessed and used by other accounts
                associated with the purchaser via Family Sharing or volume
                purchasing.
              </p>

              <h3>Maintenance and Support</h3>
              <p>
                You shall be solely responsible for providing any maintenance
                and support services with respect to the Licensed Application,
                as specified in this EULA or as required by applicable law. You
                and the End-User acknowledge that Apple has no obligation to
                provide any maintenance and support services with respect to the
                Licensed Application.
              </p>

              <h3>Warranty</h3>
              <p>
                You are solely responsible for any product warranties, whether
                express or implied by law, to the extent not effectively
                disclaimed. In the event of any failure of the Licensed
                Application to conform to any applicable warranty, the End-User
                may notify Apple, and Apple will refund the purchase price for
                the Licensed Application to that End-User. To the maximum extent
                permitted by applicable law, Apple will have no other warranty
                obligation whatsoever with respect to the Licensed Application,
                and any other claims, losses, liabilities, damages, costs, or
                expenses attributable to any failure to conform to any warranty
                will be your sole responsibility.
              </p>

              <h3>Intellectual Property Rights</h3>
              <p>
                You and the End-User acknowledge that, in the event of any
                third-party claim that the Licensed Application or the
                End-User's possession and use of that Licensed Application
                infringes any third party's intellectual property rights, you,
                not Apple, will be solely responsible for the investigation,
                defense, settlement, and discharge of any such intellectual
                property infringement claim.
              </p>

              <h3>Legal Compliance</h3>
              <p>
                The End-User represents and warrants that (i) they are not
                located in a country that is subject to a U.S. Government
                embargo or that has been designated by the U.S. Government as a
                "terrorist supporting" country, and (ii) they are not listed on
                any U.S. Government list of prohibited or restricted parties.
              </p>

              <h3>Developer Name and Address</h3>
              <p>
                For any questions, complaints, or claims regarding the Licensed
                Application, please contact Batuhan Sahan at the following
                address: Turkiye, Istanbul Email: info@dayful.app
              </p>

              <h3>Third Party Beneficiary</h3>
              <p>
                You and the End-User acknowledge and agree that Apple and its
                subsidiaries are third-party beneficiaries of this EULA. Upon
                the End-User's acceptance of this EULA, Apple will have the
                right (and will be deemed to have accepted the right) to enforce
                this EULA against the End-User as a third-party beneficiary
                thereof.
              </p>

              <p>Last updated: March 1, 2024</p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
