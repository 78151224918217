import React, { useEffect } from 'react';
import {
  AppleOutlined,
  AndroidOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Tag,
  Col,
  Row,
  Button,
  Switch,
  Form,
  message,
  Tooltip,
  Modal,
  Spin,
  Divider,
  Input,
  Tabs,
} from 'antd';
import Api from '../../../utils/api';
import config from '../../../config/index';
import styled from '@emotion/styled';
import UploadForm from './form/createIcon';
const { TextArea } = Input;
const { TabPane } = Tabs;

const InnerIcon = styled.span`
  font-size: 36px;
  padding-top: 5px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
`;

const InnerImage = styled.img`
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
`;

const InnerText = styled.span`
  display: block;
  white-space: nowrap;
  text-align: center;
  transform: scale(0.83);
`;

const List = styled.ul`
  overflow: hidden;
  direction: ltr;
  list-style: none;
  padding-inline-start: 0;
`;

const Child = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
  float: left;
  width: 20%;
  margin: 3px 0;
  overflow: hidden;
  color: #555;
  text-align: center;
  list-style: none;
  background-color: inherit;
  border-radius: 10px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:hover {
    color: #fff;
    background-color: #8ecafe;
  }

  &:hover span img {
    transform: scale(1.4);
  }
`;

const App = () => {
  const [images, setImages] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const [position, setPosition] = React.useState(['left', 'right']);

  const [form] = Form.useForm();
  const [preview, setPreview] = React.useState({
    visible: false,
    title: '',
    image: '',
  });
  const [tooltipVisible, setTooltipVisible] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [fileList, setFileList] = React.useState([]);

  const getIcons = async () => Api.get('/backgrounds');
  const getCategories = async () => Api.get('/backgrounds/categories');
  const postIcons = async (formData) =>
    Api.post(
      '/icons',
      formData,
      {},
      {
        'content-Type': 'multipart/form-data',
      }
    );

  useEffect(async () => {
    const catData = await getCategories();
    const iconData = await getIcons();
    setCategories(catData.data);
    setQuery(catData.data[0].category);
    setImages(iconData.data.filter((v) => v.size === 'small'));
    setLoading(false);
  }, []);

  const onChange = (e) => {
    setQuery(e.target.value);
    setShowAll(false);
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
    console.log('Failed:', errorInfo);
  };

  const handleSave = (e) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };
  const showModal = () => {
    setVisible(true);
  };
  const handleCancel = async () => {
    setVisible(false);
  };

  const onCreate = async (data) => {
    let formData = new FormData();
    console.log(data.files);
    formData.append('files', data.files);
    formData.append('category', data.category);
    // postIcons(formData);
    console.log(formData);
    await Api.post(
      '/icons',
      formData,
      {},
      {
        'content-Type': 'multipart/form-data',
      }
    );
  };

  const addItemToFileList = async (item) => {
    console.log('here', item.name);
  };
  const OperationsSlot = {
    left: (
      <>
        <Button
          style={{ marginBottom: 10 }}
          onClick={() => setShowAll(true)}
          type="primary"
          block>
          Show All
        </Button>
        <Input
          placeholder="Search"
          allowClear
          onChange={onChange}
          prefix={<SearchOutlined />}
        />
        <Divider />
      </>
    ),
    right: <></>,
  };
  const slot = React.useMemo(() => {
    if (position.length === 0) return null;

    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {}
    );
  }, [position]);

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Row justify="space-between">
            <Col span={12}>
              <h1>Icon List ({(images && images.length) || 0})</h1>
            </Col>
            <Col span={5}>
              <Tooltip title="Not coming any time soon 🙁" placement="leftTop">
                <Button
                  disabled
                  onClick={showModal}
                  type="primary"
                  style={{
                    float: 'right',
                    marginBottom: 16,
                  }}>
                  Upload Icons
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Tabs
            defaultActiveKey={'2'}
            tabPosition={'left'}
            tabBarExtraContent={slot}
            centered
            onTabClick={(c) => {
              setQuery(categories[c].category);
              console.log(query);
              setShowAll(false);
            }}>
            {categories.map(({ category: cat, count: count }, i) => (
              <TabPane tab={cat + '(' + count / 5 + ')'} key={i.toString()}>
                <List>
                  {images
                    .filter((v) =>
                      showAll ? v : v.category === query.toLowerCase()
                    )
                    .map((item, i) => (
                      <IconList item={item} i={i} />
                    ))}
                </List>
              </TabPane>
            ))}
          </Tabs>
        </div>
      </Spin>
      <Modal
        title="Upload Icons"
        visible={visible}
        onOk={handleSave}
        handleCancel={handleCancel}
        onCancel={handleCancel}
        okText="Save"
        confirmLoading={confirmLoading}
        footer={[
          <Button key="back" onClick={handleCancel} disabled={confirmLoading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={handleSave}>
            Upload
          </Button>,
        ]}>
        <UploadForm
          setPreview={setPreview}
          preview={preview}
          form={form}
          setFileList={addItemToFileList}
          onFinish={handleSave}
          onFinishFailed={onFinishFailed}
        />
        <Divider />
      </Modal>
    </>
  );
};

class IconList extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { item, i } = this.props;
    return (
      <Tooltip
        title={
          item.noun.join(', ') +
          (item.verb.length > 0 ? ', ' : '') +
          item.verb.join(', ')
        }
        color={'#108ee9ba'}
        key={i.toString()}>
        <Child>
          <InnerIcon>
            <InnerImage
              // src={`data:image/png;base64, ${item.base64}`}
              src={config.STATIC_URL + 'backgrounds/' + item.filename}
              height="180"
              width="180"
            />
          </InnerIcon>
          <InnerText>
            <Tag
              color="default"
              style={{
                marginRight: 0,
                textTransform: 'capitalize',
                fontSize: 10,
              }}>
              {item.key}
            </Tag>
          </InnerText>
        </Child>
      </Tooltip>
    );
  }
}

export default App;
