import React, { useState } from 'react';
import { message, Card } from 'antd';
import { useDispatch } from 'react-redux';
import Api from '../../../utils/api';
import Auth from './components/loginForm';
import OTP from './components/otpForm';
import logo from '../../../images/dashboard.png';
import { loggedIn } from './actions';
function App() {
  const [screen, setScreen] = useState('login');
  const dispatch = useDispatch();
  const [user, setUser] = useState('');
  const onFinish = async (values) => {
    const { username, passcode } = values;
    if (username) {
      try {
        const user = await authenticate({ username });
        message.success('One-time password sent to your email.');
        setScreen('otp');
        user.data.username = username;
        setUser(user.data);
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish', err);
      }
    }
    if (passcode) {
      try {
        const loginResponse = await login({ userId: user?._id, otp: passcode });
        if (!!loginResponse?.data?.status) {
          message.success('Successfully logged in, Welcome to Dayful');
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', loginResponse?.data?.accessToken);
          dispatch(loggedIn(JSON.stringify(user)));
        }
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish', err);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const authenticate = async ({ username }) => {
    const data = { username };
    return Api.post('/authentications', data);
  };

  const login = async ({ userId, otp }) => {
    const data = { userId, otp };
    return Api.post('/authentications/login', data);
  };

  return (
    <div className="App-header3">
      <Card
        style={{ marginTop: 16 }}
        type="inner"
        title="Dashboard"
        extra={<img src={logo} className="logo" alt="logo" />}>
        {screen === 'login' ? (
          <Auth onFinish={onFinish} onFinishFailed={onFinishFailed} />
        ) : screen === 'otp' ? (
          <OTP onFinish={onFinish} onFinishFailed={onFinishFailed} />
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
}

export default App;
