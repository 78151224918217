import * as actions from './types';

export const initialState = { data: [], error: null, loading: false };

export default function routineReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_ROUTINE_START:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_ROUTINE_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.FETCH_ROUTINE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    case actions.ROUTINE_PUSH:
      return {
        ...state,
        data: [action.data, ...state.data],
      };
    case actions.ROUTINE_UPDATE:
      const index = state.data.findIndex((v) => v.key === action.key);
      const newArray = [...state.data];
      newArray[index] = action.data;
      return {
        ...state,
        data: newArray,
      };
    case actions.ROUTINE_ARCHIVE:
      const filteredData = state.data.filter((v) => v._id !== action.id);
      return {
        ...state,
        data: filteredData,
      };
    default:
      return state;
  }
}
