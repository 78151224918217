export default {
  development: {
    BASE_URL: 'https://razor.dayful.app/v1/dashboard',
    STATIC_URL: 'https://razor.dayful.app/static/assets/',
  },
  production: {
    BASE_URL: 'https://razor.dayful.app/v1/dashboard',
    STATIC_URL: 'https://razor.dayful.app/static/assets/',
  },
};
