import React from 'react';
import Board from '../../../components/Board';
import { authorQuoteMap } from '../../../components/Board/data';

const App = () => {
  const action = (type, data) => {
    console.log(type, data);
  };
  return <Board initial={authorQuoteMap} action={action} />;
};

export default App;
