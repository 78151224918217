import { Action, Dispatch } from 'redux';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOGIN_REQUEST,
  AUTH_CHECK,
} from './types';
import history from '../../../helpers/history';

export function init() {
  return () => {
    const user = localStorage.getItem('user');
    if (user) {
      return {
        type: LOGIN_SUCCESS,
        token: JSON.parse(user),
      };
    } else {
      return {
        type: LOGIN_ERROR,
        token: false,
      };
    }
  };
}

export function authenticationCheck() {
  return (dispatch) => {
    const user = localStorage.getItem('user');
    if (user) {
      dispatch({
        type: LOGIN_SUCCESS,
        token: JSON.parse(user),
      });
    } else {
      dispatch({
        type: LOGIN_ERROR,
        token: false,
      });
    }
  };
}

// export function authenticationCheck() {
//     return (dispatch) => {
//         Auth0.handleAuthentication();
//         const isAuthenticate = isAuthenticated();
//         dispatch({
//             type: LOGIN_SUCCESS,
//             token: isAuthenticated,
//         });
//     };
// }

export function loggedIn(token) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_SUCCESS,
      token,
    });
  };
}

export function logOut() {
  return (dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
}
