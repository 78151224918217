import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Select,
  Input,
  Space,
  Upload,
  InputNumber,
  Radio,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const { Option } = Select;
const languages = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' },
  { label: 'German', value: 'german' },
  { label: 'French', value: 'french' },
  { label: 'Turkish', value: 'turkish' },
];

class CreateIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    fileList: [],
  };

  render() {
    const { setFieldsValue } = this.props.form;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const props = {
      handleChange: ({ fileList }) => {
        console.log();
      },

      beforeUpload: (file) => {
        this.setState(
          (prevState) => ({
            fileList: [...prevState.fileList, file],
          }),
          () => {
            setFieldsValue({
              files: this.state.fileList,
            });
            console.log(this.state.fileList);
          }
        );

        return false;
      },

      onRemove: (file) => {
        this.setState(
          {
            fileList: fileList.filter((item) => item.uid !== file.uid),
          },
          () => {
            setFieldsValue({
              files: this.state.fileList,
            });
          }
        );
      },

      handlePreview: async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
      },
    };
    return (
      <Form
        form={this.props.form}
        layout="vertical"
        name="form_in_modal"
        onFinish={this.props.onFinish}
        onFinishFailed={this.props.onFinishFailed}>
        <Form.Item name="_id" label="_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="files" label="Key" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="category"
          label="Category"
          rules={[
            { required: true, message: 'Please input the icon category name' },
          ]}>
          <Input />
        </Form.Item>
        <Upload
          listType="picture-card"
          // onPreview={this.props.handlePreview}
          {...props}
          multiple>
          <div>
            <PlusOutlined />
          </div>
        </Upload>
      </Form>
    );
  }
}

export default CreateIcon;
