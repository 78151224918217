import React, { useEffect } from 'react';
import logo from '../../images/logo2.png';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import './landing.css';

const ButtonWrapper = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 32px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  /* background: rgb(244, 244, 245); */
  border-width: 2px;
  border-style: solid;
  border-color: #ffc146;
  border-radius: 4px;
  background: #ffffff94;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  padding: 8px;
  margin: 0px;
  color: #ffc146;
  font-size: 13px;
  line-height: 24px;
  text-decoration: capitilize;

  &:hover {
    color: #fff;
    background: #ffc146;
  }

  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

function App() {
  const [dashboardVisible, setDashboardVisible] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  useEffect(() => {
    let user = localStorage.getItem('user');
    let accessToken = localStorage.getItem('token');
    user = JSON.parse(user);
    if (user && accessToken) {
      setUsername(user.username);
      setDashboardVisible(true);
    }
  });
  return (
    <div className="App">
      <header className="App-header5">
        <div
          style={{
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            marginTop: 100,
          }}>
          <div
            style={{
              backgroundColor: '#ffffff90',
              width: '90%',
              backgroundColor: '#fff',
              borderRadius: '10px',
              margin: 'auto',
              marginBottom: '10%',
              color: '#000',
            }}>
            <div className="container">
              <h1>Terms of Service</h1>

              <h2>Effective Date: June 29, 2023</h2>

              <p>
                Welcome to Dayful! These Terms of Service ("Terms") govern your
                use of the Dayful mobile application ("the App") and any related
                services provided by Dayful ("we," "us," or "our"). By accessing
                or using the App, you agree to be bound by these Terms. If you
                do not agree with these Terms, please refrain from using the
                App.
              </p>

              <h3>1. Subscription</h3>
              <p>
                1.1 The Dayful App offers both a basic free plan and a premium
                plan. Both plans grant access to the App, but the basic free
                plan has limited features, while the premium plan offers
                additional features and functionalities. By choosing to use the
                App, you acknowledge and accept the limitations of the basic
                free plan.
              </p>
              <p>
                1.2 The premium plan requires a subscription to access its
                additional features. Your subscription will start automatically
                following the end of the trial period, if applicable, and will
                continue on a recurring basis until you cancel. You will be
                charged for the current subscription period according to your
                chosen subscription plan.
              </p>
              <p>
                1.3 To cancel your subscription, it is your responsibility to do
                so through the service where you signed up, e.g., the App Store
                or Google Play Store. Paid subscriptions cannot be terminated
                before the end of the period for which it is already paid.
                Dayful will not refund fees that you have already paid. A
                missing payment allows Dayful to deactivate an account.
              </p>

              <h3>2. Cancellation</h3>
              <p>
                2.1 It is your own responsibility to cancel your subscription
                through the service where you signed up, e.g., App Store or
                Google Play Store. You will be charged for the current
                subscription period. Please note that paid subscriptions cannot
                be terminated before the end of the period for which you have
                already paid. Dayful will not refund fees that are already paid.
              </p>

              <h3>3. Data</h3>
              <p>
                3.1 We take your privacy and data protection very seriously. We
                share and/or analyze your personal information only in the
                limited scenarios and for the limited purposes as explained in
                our Privacy Policy. By using Dayful, you agree to the collection
                and use of personal information in accordance with our Privacy
                Policy.
              </p>

              <h3>4. Maintenance and Stability</h3>
              <p>
                4.1 The Dayful service is developed continuously. We reserve the
                right to update and change the content in the service. We strive
                for the best possible maintenance stability but are not liable
                for any interruptions or breakdowns caused by circumstances
                beyond our control. This includes, but is not limited to,
                electrical cuts, issues with internet connection, issues with
                hardware, hacker attacks, viruses, or other types of force
                majeure.
              </p>

              <h3>5. Responsibility</h3>
              <p>
                5.1 The use of Dayful is at your own risk, and we are not liable
                to you or anyone else if interference with or damage to your
                computer systems occurs or if you suffer any loss, cost, or
                expense in connection with your use of our service. We disclaim
                all responsibility and liability for any direct or indirect
                loss, damage, cost, or expense whatsoever in the use of or
                reliance upon Dayful.
              </p>
              <p>
                5.2 We agree to use commercially reasonable technical and
                organizational measures designed to prevent unauthorized access,
                use, alteration, or disclosure of any Service or Customer Data.
                However, we will have no responsibility for errors in
                transmission, unauthorized third-party access, or other causes
                beyond our control. All data is stored securely to prevent
                unauthorized third-party access. We cannot be held responsible
                for errors that occur due to circumstances beyond our control.
                This includes, but is not limited to, electrical cuts, issues
                with internet connection, issues with hardware, hacker attacks,
                viruses, or other types of force majeure.
              </p>

              <h3>6. Data Security</h3>
              <p>
                6.1 When signing up to use Dayful, you complete a sign-up form
                with your name and email address. This information will be
                stored for the purpose of providing you with the best possible
                service. When you create a subscription with Dayful, your
                payment information is handled through the App Store or Google
                Play. Dayful only stores whether you have an active or inactive
                subscription and does not store any of your payment information.
                The customer accepts that Dayful is entitled to use third-party
                suppliers in all conditions, including development, maintenance,
                and data storage. These suppliers may be located outside of
                Denmark. All data is owned by Dayful. For more information on
                the processing of your data, please refer to our Privacy Policy.
              </p>

              <h3>7. Changes</h3>
              <p>
                7.1 Dayful reserves the right to change and update these Terms
                of Service. We also reserve the right to make price changes with
                a minimum of 30 days' notice. You will be notified of
                significant changes by email.
              </p>

              <h3>Acknowledgement</h3>
              <p>
                You and the End-User acknowledge that this End-User License
                Agreement ("EULA") is concluded between you (the developer) and
                the End-User, and not with Apple Inc. ("Apple"). You acknowledge
                that you are solely responsible for the Licensed Application and
                its content. The EULA shall not provide usage rules for Licensed
                Applications that conflict with the Apple Media Services Terms
                and Conditions as of the Effective Date, which you acknowledge
                you have had the opportunity to review.
              </p>

              <h3>Scope of License</h3>
              <p>
                The license granted to the End-User for the Licensed Application
                is limited to a non-transferable license to use the Licensed
                Application on any Apple-branded Products that the End-User owns
                or controls, in accordance with the Usage Rules set forth in the
                Apple Media Services Terms and Conditions. However, the Licensed
                Application may be accessed and used by other accounts
                associated with the purchaser via Family Sharing or volume
                purchasing.
              </p>

              <h3>Maintenance and Support</h3>
              <p>
                You shall be solely responsible for providing any maintenance
                and support services with respect to the Licensed Application,
                as specified in this EULA or as required by applicable law. You
                and the End-User acknowledge that Apple has no obligation to
                provide any maintenance and support services with respect to the
                Licensed Application.
              </p>

              <h3>Warranty</h3>
              <p>
                You are solely responsible for any product warranties, whether
                express or implied by law, to the extent not effectively
                disclaimed. In the event of any failure of the Licensed
                Application to conform to any applicable warranty, the End-User
                may notify Apple, and Apple will refund the purchase price for
                the Licensed Application to that End-User. To the maximum extent
                permitted by applicable law, Apple will have no other warranty
                obligation whatsoever with respect to the Licensed Application,
                and any other claims, losses, liabilities, damages, costs, or
                expenses attributable to any failure to conform to any warranty
                will be your sole responsibility.
              </p>

              <h3>Product Claims</h3>
              <p>
                You and the End-User acknowledge that you, not Apple, are
                responsible for addressing any claims of the End-User or any
                third party relating to the Licensed Application or the
                end-user's possession and/or use of that Licensed Application,
                including but not limited to: (i) product liability claims; (ii)
                any claim that the Licensed Application fails to conform to any
                applicable legal or regulatory requirement; and (iii) claims
                arising under consumer protection, privacy, or similar
                legislation, including in connection with Your Licensed
                Application's use of the HealthKit and HomeKit frameworks. The
                EULA shall not limit your liability to the End-User beyond what
                is permitted by applicable law.
              </p>

              <h3>Intellectual Property Rights</h3>
              <p>
                You and the End-User acknowledge that, in the event of any
                third-party claim that the Licensed Application or the
                End-User's possession and use of that Licensed Application
                infringes any third party's intellectual property rights, you,
                not Apple, will be solely responsible for the investigation,
                defense, settlement, and discharge of any such intellectual
                property infringement claim.
              </p>

              <h3>Legal Compliance</h3>
              <p>
                The End-User represents and warrants that (i) they are not
                located in a country that is subject to a U.S. Government
                embargo or that has been designated by the U.S. Government as a
                "terrorist supporting" country, and (ii) they are not listed on
                any U.S. Government list of prohibited or restricted parties.
              </p>

              <h3>Developer Name and Address</h3>
              <p>
                For any questions, complaints, or claims regarding the Licensed
                Application, please contact Batuhan Sahan at the following
                address: Turkiye, Istanbul Email: info@dayful.app
              </p>

              <h3>Third Party Terms of Agreement</h3>
              <p>
                The End-User must comply with any applicable third-party terms
                of agreement when using the Licensed Application, including
                wireless data service agreements and other relevant agreements.
              </p>

              <h3>Third Party Beneficiary</h3>
              <p>
                You and the End-User acknowledge and agree that Apple and its
                subsidiaries are third-party beneficiaries of this EULA. Upon
                the End-User's acceptance of this EULA, Apple will have the
                right (and will be deemed to have accepted the right) to enforce
                this EULA against the End-User as a third-party beneficiary
                thereof.
              </p>

              <p>Last updated: June 29, 2023</p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
