import React, { useEffect } from 'react';
import './styles/App.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import Router from './Router';
import Boot from './redux/boot';
import { authenticationCheck } from './containers/DashboardPage/Auth/actions';

function App() {
  useEffect(() => {
    store.dispatch(authenticationCheck());
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
