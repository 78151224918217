import React from 'react';
import { Row, Col, Menu, Dropdown } from 'antd';

import { LogoutOutlined, MenuFoldOutlined } from '@ant-design/icons';
const Navbar = () => {
  const refresh = (e) => {
    e.preventDefault();
    window.location.reload(true);
  };

  const logout = () => {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');
    window.location.reload(true);
  };

  return (
    <Row type="flex" style={{ flexDirection: 'row-reverse' }}>
      <Col>
        <Dropdown
          overlay={() => {
            return (
              <Menu>
                <Menu.Item onClick={logout}>
                  <LogoutOutlined /> Logout
                </Menu.Item>
              </Menu>
            );
          }}
          trigger={['hover']}>
          <span style={{ userSelect: 'none', cursor: 'pointer' }}>
            <MenuFoldOutlined
              className="hoverable action"
              style={{
                height: '100%',
                width: '30px',
              }}
            />
          </span>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default Navbar;
