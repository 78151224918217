import { Action, Dispatch } from 'redux';
import uuid from 'uuid/v4';
import Api from '../../../utils/api';
import {
  FETCH_CHALLENGE_HABITS_ERROR,
  FETCH_CHALLENGE_HABITS_START,
  FETCH_CHALLENGE_HABITS_SUCCESS,
  CHALLENGE_HABITS_PUSH,
  CHALLENGE_HABITS_UPDATE,
  CHALLENGE_HABITS_ARCHIVE,
} from './types';

const getChallengeHabits = async () => Api.get('/challenges/builder');

export function makeFetchChallengeHabits() {
  return async function (dispatch) {
    try {
      await startAction(FETCH_CHALLENGE_HABITS_START, dispatch);
      const response = await getChallengeHabits();
      console.log('here', response);
      const { data } = response;
      const { challengeHabits, challenges, habits } = data;
      if (challengeHabits.length > 0) {
        challenges.forEach((item, i) => {
          const builderData = challengeHabits.find(
            (v) => v.challenge === item._id
          );
          console.log('1');
          item.index = builderData.index;
          console.log('2');
          item.habits = builderData.habits;
          item.habits.forEach((h) => {
            const index = h.index;
            const habit = habits.find((c) => c._id === h.habit);
            h.name = habit.name;
            h.key = habit.key;
            h.description = habit.description;
            h.archived = habit.archived;
            h.time = habit.time || 0;
            h.id = uuid();
            h._id = habit._id;
            h.types = habit.types;
            h.index = index;
            h.icon = {
              filename: habit.icon?.filename || '',
            };
          });
          item.habits.sort((e, r) => e.index - r.index);
        });
        challenges.sort((y, t) => y.index - t.index);
      }
      dispatch({
        type: FETCH_CHALLENGE_HABITS_SUCCESS,
        challenges,
        habits,
      });
    } catch (error) {
      await errorAction(FETCH_CHALLENGE_HABITS_ERROR, dispatch, error);
    }
  };
}

export function makePushChallengeHabits(data) {
  return async function (dispatch) {
    dispatch({
      type: CHALLENGE_HABITS_PUSH,
      data,
    });
  };
}

export function makeUpdateChallengeHabits(key, data) {
  return async function (dispatch) {
    console.log('UPDATE_CHALLENGE_HABITS', key, data);
    dispatch({
      type: CHALLENGE_HABITS_UPDATE,
      key,
      data,
    });
  };
}

export function makeArchiveChallengeHabits(id) {
  return async function (dispatch) {
    dispatch({
      type: CHALLENGE_HABITS_ARCHIVE,
      id,
    });
  };
}

export const startAction = async (type, dispatch) => {
  await dispatch({
    type,
  });
};

export const errorAction = async (type, dispatch, error) => {
  await dispatch({
    type,
    error: error?.statusText || error?.data,
  });
};
