import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectHome = (state) => state.Auth || initialState;

const selectToken = createSelector(selectHome, (Auth) => Auth.token);

// const selectToken = () =>
//   createSelector(
//     selectHome,
//     Auth => Auth.token,
//   );
// useSelector(selectToken())

export { selectToken };
