import axios from 'axios';
import config from '../config/index';

const Api = {
  _getDefaultHeader: () => {
    const accessToken = localStorage.getItem('token');
    return {
      'Content-Type': 'application/json',
      Authorization: `${accessToken}`,
    };
  },
  _request: async (
    method,
    url,
    data = {},
    params = {},
    headers = {},
    baseUrl
  ) => {
    headers = Object.assign(Api._getDefaultHeader(), headers);
    let requestUrl = baseUrl ? `${baseUrl}${url}` : `${config.BASE_URL}${url}`;
    let response;
    try {
      response = await axios({
        method: method,
        url: requestUrl,
        data: data,
        params: params,
        headers: headers,
      });
    } catch (error) {
      if (error.response?.data?.code === 403) {
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('accessToken');
        window.location.href = '/';
      }
      throw error.response;
    }
    return response.data;
  },
  get: (url, params = {}, headers = {}, baseUrl) => {
    return Api._request('get', url, {}, params, headers, baseUrl);
  },
  put: (url, data, params = {}, headers = {}, baseUrl) => {
    return Api._request('put', url, data, params, headers, baseUrl);
  },
  post: (url, data, params = {}, headers = {}, baseUrl) => {
    return Api._request('post', url, data, params, headers, baseUrl);
  },
  delete: (url, headers = {}, baseUrl) => {
    return Api._request('delete', url, {}, {}, headers, baseUrl);
  },
};

export default Api;
