import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoutineHabits = (state) => state.RoutineHabits || initialState;

const selectRoutineHabitsHabitsData = createSelector(
  selectRoutineHabits,
  (RoutineHabits) => RoutineHabits.habits
);

const selectRoutineHabitsRoutinesData = createSelector(
  selectRoutineHabits,
  (RoutineHabits) => RoutineHabits.routines
);

const selectRoutineHabitsLoading = createSelector(
  selectRoutineHabits,
  (RoutineHabits) => RoutineHabits.loading
);
const selectRoutineHabitsError = createSelector(
  selectRoutineHabits,
  (RoutineHabits) => RoutineHabits.error
);

export {
  selectRoutineHabitsHabitsData,
  selectRoutineHabitsRoutinesData,
  selectRoutineHabitsLoading,
  selectRoutineHabitsError,
};
