import { Action, Dispatch } from 'redux';
import Api from '../../../utils/api';
import {
  FETCH_JOURNEY_ERROR,
  FETCH_JOURNEY_START,
  FETCH_JOURNEY_SUCCESS,
  JOURNEY_PUSH,
  JOURNEY_UPDATE,
  JOURNEY_ARCHIVE,
} from './types';

const getJourneys = async () => Api.get('/journeys');

export function makeFetchJourneys() {
  return async function (dispatch) {
    try {
      await startAction(FETCH_JOURNEY_START, dispatch);
      const response = await getJourneys();
      const { data } = response;
      dispatch({
        type: FETCH_JOURNEY_SUCCESS,
        data,
      });
    } catch (error) {
      await errorAction(FETCH_JOURNEY_ERROR, dispatch, error);
    }
  };
}

export function makePushJourney(data) {
  return async function (dispatch) {
    dispatch({
      type: JOURNEY_PUSH,
      data,
    });
  };
}

export function makeUpdateJourney(key, data) {
  return async function (dispatch) {
    console.log('UPDATE_JOURNEY', key, data);
    dispatch({
      type: JOURNEY_UPDATE,
      key,
      data,
    });
  };
}

export function makeArchiveJourney(id) {
  return async function (dispatch) {
    dispatch({
      type: JOURNEY_ARCHIVE,
      id,
    });
  };
}

export const startAction = async (type, dispatch) => {
  await dispatch({
    type,
  });
};

export const errorAction = async (type, dispatch, error) => {
  await dispatch({
    type,
    error: error.statusText || error.data,
  });
};
