import * as actions from './types';

export const initialState = {
  habits: [],
  journeys: [],
  error: null,
  loading: false,
};

export default function journeyHabitsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_JOURNEY_HABITS_START:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_JOURNEY_HABITS_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actions.FETCH_JOURNEY_HABITS_SUCCESS:
      return {
        ...state,
        habits: action.habits,
        journeys: action.journeys,
        loading: false,
      };
    case actions.JOURNEY_HABITS_PUSH:
      return {
        ...state,
        data: [action.data, ...state.data],
      };
    case actions.JOURNEY_HABITS_UPDATE:
      const index = state.data.findIndex((v) => v.key === action.key);
      const newArray = [...state.data];
      newArray[index] = action.data;
      return {
        ...state,
        data: newArray,
      };
    case actions.JOURNEY_HABITS_ARCHIVE:
      const filteredData = state.data.filter((v) => v._id !== action.id);
      return {
        ...state,
        data: filteredData,
      };
    default:
      return state;
  }
}
