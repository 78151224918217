import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import { Layout, Menu, BackTop } from 'antd';
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  HomeTwoTone,
  RocketTwoTone,
  CarryOutTwoTone,
  StarTwoTone,
  SmileTwoTone,
  FlagTwoTone,
  NotificationTwoTone,
  TrophyTwoTone,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import Navbar from '../../components/Navbar';
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

function App() {
  return (
    <Layout theme="light" style={{ height: '100vh' }}>
      <Sider
        style={{
          background: '#f4f4f5',
          height: '100vh',
          left: '0px',
        }}
        theme="dark"
        breakpoint="lg"
        collapsedWidth="0"
        // onBreakpoint={(broken) => {
        //   console.log(broken);
        // }}
        // onCollapse={(collapsed, type) => {
        //   console.log(collapsed, type);
        // }}
      >
        <Logo />
        <Menu
          theme="light"
          style={{
            background: '#f4f4f5',
          }}
          mode="inline"
          defaultSelectedKeys={['1']}>
          <Menu.Item key="1" icon={<HomeTwoTone twoToneColor="#19192E" />}>
            <Link to="main">Dashboard</Link>
          </Menu.Item>
          <SubMenu
            key="sub0"
            icon={<FlagTwoTone twoToneColor="#059185" />}
            title="Categories">
            <Menu.Item key="30">
              <Link to="category-routine-builder">Routine</Link>
            </Menu.Item>
            <Menu.Item key="31">
              <Link to="category-journey-builder">Journey</Link>
            </Menu.Item>
            <Menu.Item key="32">
              <Link to="category-challenge-builder">Challenge</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub1"
            icon={<FlagTwoTone twoToneColor="#059185" />}
            title="Journeys">
            <Menu.Item key="6">
              <Link to="journey">Edit Appearance</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="journey-builder">Content Builder</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub2"
            icon={<StarTwoTone twoToneColor="#eb2f96" />}
            title="Routines">
            <Menu.Item key="8">
              <Link to="routine">Edit Appearance</Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Link to="routine-builder">Content Builder</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub3"
            icon={<TrophyTwoTone twoToneColor="#4F57CE" />}
            title="Challenges">
            <Menu.Item key="10">
              <Link to="challenge">Edit Appearance</Link>
            </Menu.Item>
            <Menu.Item key="11">
              <Link to="challenge-builder">Content Builder</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="4" icon={<CarryOutTwoTone twoToneColor="#096dd9" />}>
            <Link to="habit">Habits</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<SmileTwoTone twoToneColor="#faad14" />}>
            <Link to="icon-editor">Icons</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<SmileTwoTone twoToneColor="#faad14" />}>
            <Link to="background-editor">Backgrounds</Link>
          </Menu.Item>
          <Menu.Item key="13" icon={<NotificationTwoTone twoToneColor="red" />}>
            <a href="https://blog.dayful.app/ghost" target="_blank">
              Blog
            </a>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="header">
          <div></div>
          <Navbar />
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360, height: 'auto' }}>
            <Outlet />
          </div>
          <Footer className="footer" style={{ textAlign: 'center' }}>
            Dayful ©2021 Happenify Inc.
          </Footer>
        </Content>
      </Layout>
      <BackTop />
    </Layout>
  );
}

export default App;
