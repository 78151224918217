import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { Tag, Tooltip } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import {
  QuestionCircleTwoTone,
  FlagTwoTone,
  StarTwoTone,
  ExclamationCircleOutlined,
  TrophyTwoTone,
} from '@ant-design/icons';
import config from '../../../config/index';

const Badge = styled.small`
  flex-grow: 0;
  margin: 0;
  background-color: ${colors.B50};
  border-radius: 4px;
  font-weight: normal;
  padding: 4px;
  width: max-content;
`;

const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: ${(props) => (props.isDragging ? '#EFEFF5' : '#FFF')};
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0 1px 2px 0 rgb(9 30 66 / 25%);
`;

const Clone = styled(Item)`
  ~ div {
    transform: none !important;
  }
`;

class InnerKioskList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.items !== this.props.items) {
      return true;
    }

    return false;
  }
  render() {
    return this.props.items.map((item, index) => (
      <Draggable key={item._id} draggableId={item._id} index={index}>
        {(provided, snapshot) => (
          <React.Fragment>
            <Item
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
              style={provided.draggableProps.style}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {item.icon?.filename && (
                  <img
                    style={{ marginRight: 10 }}
                    src={config.STATIC_URL + 'icons/' + item.icon.filename}
                    height="16"
                    width="16"
                  />
                )}
                <Tooltip
                  placement="left"
                  title={item.description}
                  overlayStyle={{ zIndex: 9999999 }}>
                  {item.name}
                </Tooltip>
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                {!!item.archived && (
                  <Tooltip
                    placement="left"
                    title="This habit archived but users still accessing this habit, please be careful when using this.">
                    <Tag style={{ marginRight: 5 }} color="warning">
                      <ExclamationCircleOutlined />
                    </Tag>
                  </Tooltip>
                )}
                <Tag
                  style={{
                    margin: 0,
                    marginRight: 5,
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {item.types.map((v) => (
                      <div style={{ paddingRight: 2, paddingLeft: 2 }}>
                        {v === 'journey' ? (
                          <Tooltip placement="left" title={'Journey'}>
                            <FlagTwoTone twoToneColor="#059185" />
                          </Tooltip>
                        ) : v === 'routine' ? (
                          <Tooltip placement="left" title={'Routine'}>
                            <StarTwoTone twoToneColor="#E68372" />
                          </Tooltip>
                        ) : (
                          <Tooltip placement="left" title={'Challenge'}>
                            <TrophyTwoTone twoToneColor="#4F57CE" />
                          </Tooltip>
                        )}
                      </div>
                    ))}
                  </div>
                </Tag>
                <Badge>{Math.floor(item.time / 60)} m</Badge>
              </div>
            </Item>
            {snapshot.isDragging && (
              <Clone>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  {item.icon?.filename && (
                    <img
                      style={{ marginRight: 10 }}
                      src={config.STATIC_URL + 'icons/' + item.icon.filename}
                      height="16"
                      width="16"
                    />
                  )}
                  {item.name}
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  {!!item.archived && (
                    <Tooltip
                      placement="left"
                      title="This habit archived but users still accessing this habit, please be careful when using this.">
                      <Tag style={{ marginRight: 5 }} color="warning">
                        <ExclamationCircleOutlined />
                      </Tag>
                    </Tooltip>
                  )}
                  <Tag
                    style={{
                      margin: 0,
                      marginRight: 5,
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {item.types.map((v) => (
                        <div style={{ paddingRight: 2, paddingLeft: 2 }}>
                          {v === 'journey' ? (
                            <Tooltip placement="left" title={'Journey'}>
                              <FlagTwoTone twoToneColor="#059185" />
                            </Tooltip>
                          ) : v === 'routine' ? (
                            <Tooltip placement="left" title={'Routine'}>
                              <StarTwoTone twoToneColor="#E68372" />
                            </Tooltip>
                          ) : (
                            <Tooltip placement="left" title={'Challenge'}>
                              <TrophyTwoTone twoToneColor="#4F57CE" />
                            </Tooltip>
                          )}
                        </div>
                      ))}
                    </div>
                  </Tag>
                  <Badge>{Math.floor(item.time / 60)} m</Badge>
                </div>
              </Clone>
            )}
          </React.Fragment>
        )}
      </Draggable>
    ));
  }
}

export default InnerKioskList;
