import React, { useEffect } from 'react';
import logo from '../../images/logo2.png';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ArrowRightOutlined } from '@ant-design/icons';
import './landing.css';

const ButtonWrapper = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 32px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  /* background: rgb(244, 244, 245); */
  border-width: 2px;
  border-style: solid;
  border-color: #ffc146;
  border-radius: 4px;
  background: #ffffff94;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  padding: 8px;
  margin: 0px;
  color: #ffc146;
  font-size: 13px;
  line-height: 24px;
  text-decoration: capitilize;

  &:hover {
    color: #fff;
    background: #ffc146;
  }

  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

function App() {
  const [dashboardVisible, setDashboardVisible] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  useEffect(() => {
    let user = localStorage.getItem('user');
    let accessToken = localStorage.getItem('token');
    user = JSON.parse(user);
    if (user && accessToken) {
      setUsername(user.username);
      setDashboardVisible(true);
    }
  });
  return (
    <div className="App">
      <header className="App-header5">
        <div
          style={{
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            marginTop: 100,
          }}>
          <div
            style={{
              backgroundColor: '#ffffff90',
              width: '90%',
              backgroundColor: '#fff',
              borderRadius: '10px',
              margin: 'auto',
              marginBottom: '10%',
              color: '#000',
            }}>
            <div className="container">
              <h1>Dayful Privacy Policy</h1>
              <p>
                At Dayful, we are committed to protecting your privacy and
                safeguarding your personal information. This Privacy Policy
                explains how we collect, use, and protect your information when
                you use our mobile application, Dayful, which is available on
                the App Store and Google Play Store. By using our app, you agree
                to the terms of this Privacy Policy.
              </p>
              <h2>Collection of Information</h2>
              <p>
                Dayful does not require users to sign up or provide any personal
                information. The app allows you to build routines, habits, and
                take notes. All data is stored in the device's internal storage
                and is not collected or accessed by us.
              </p>
              <p>
                If you choose to sign up for an account using Apple or Google
                login, automatic backup can be enabled. In this case, any
                changes you make to the database will be automatically saved to
                an encrypted cloud server that can only be accessed by you. We
                do not have access to this data, and it can only be synced
                across devices if you log in with your Apple or Google account.
              </p>
              <p>
                We do not collect any location information or personal
                identifiers such as your name or contact information. We only
                collect crash reports if you give us permission to do so.
              </p>
              <h2>Third-Party Services</h2>
              <p>
                Dayful uses third-party services that may collect information
                used to identify you. These services include{' '}
                <a
                  href="https://www.google.com/policies/privacy/"
                  target="_blank">
                  {' '}
                  Google Play Services and Firebase
                </a>{', '}
                <a href="https://www.sentry.io/privacy/" target="_blank">
                  Sentry
                </a>{' '}
                and{' '}
                <a href="https://www.revenuecat.com/privacy" target="_blank">
                  RevenueCat
                </a>
                .
              </p>
              <h2>Use of Information</h2>
              <p>
                Any information that we collect from you is used to improve the
                app and provide a better user experience. We do not sell or
                share any information with third parties.
              </p>
              <p>
                If you choose to sign up for an account and enable automatic
                backup, your data will be securely stored on an encrypted cloud
                server that can only be accessed by you. This information is
                used solely for the purpose of syncing your data across devices
                and is not shared with anyone else.
              </p>
              <h2>Security</h2>
              <p>
                We take the security of your personal information very
                seriously. We use industry-standard measures to protect your
                data from unauthorized access, modification, disclosure, and
                destruction.
              </p>
              <h2>Changes to Privacy Policy</h2>
              <p>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes.
              </p>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{' '}
                <a href="mailto:info@dayful.app">info@dayful.app</a>.
              </p>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
