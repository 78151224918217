import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@atlaskit/theme';
import { Draggable } from 'react-beautiful-dnd';
import { Popconfirm, Tag, Tooltip } from 'antd';
import {
  DeleteOutlined,
  MenuOutlined,
  QuestionCircleTwoTone,
  FlagTwoTone,
  StarTwoTone,
  ExclamationCircleOutlined,
  TrophyTwoTone,
} from '@ant-design/icons';

import config from '../../../config/index';

const Badge = styled.small`
  flex-grow: 0;
  margin: 0;
  background-color: ${colors.B50};
  border-radius: 4px;
  font-weight: normal;
  padding: 4px;
  width: max-content;
`;

const Item = styled.div`
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  align-items: center;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  background: ${(props) => (props.isDragging ? '#EFEFF5' : '#FFF')};
  box-shadow: 0 1px 2px 0 rgb(9 30 66 / 25%);
`;

const Handle = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none;
  margin: -0.5rem 0.5rem -0.5rem -0.5rem;
  padding: 0.5rem;
  line-height: 1.5;
  border-radius: 3px 0 0 3px;
  background: #fff;
  border-right: 1px solid #ddd;
  color: #000;
`;
const Trash = styled.div`
  margin-left: auto;
  margin-right: 5px;
  padding-left: 10px;
`;

class InnerHabitList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.items !== this.props.items) {
      return true;
    }

    return false;
  }
  render() {
    return this.props.items.map((item, index) => (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => (
          <Item
            ref={provided.innerRef}
            {...provided.draggableProps}
            isDragging={snapshot.isDragging}
            style={provided.draggableProps.style}
            {...provided.dragHandleProps}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {item.icon?.filename && (
                <img
                  style={{ marginRight: 10 }}
                  src={config.STATIC_URL + 'icons/' + item.icon.filename}
                  height="16"
                  width="16"
                />
              )}
              <Tooltip placement="left" title={item.description}>
                {item.name}
              </Tooltip>
            </span>
            <Tooltip placement="left" title="Remove">
              <Trash>
                <Popconfirm
                  title={
                    !!item.archived
                      ? 'Sure to remove? Be careful this habit is archived!'
                      : 'Sure to remove?'
                  }
                  onConfirm={() => this.props.removeItem(item.id)}>
                  <DeleteOutlined />
                </Popconfirm>
              </Trash>
            </Tooltip>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              {!!item.archived && (
                <Tooltip
                  placement="left"
                  title="This habit archived but users still accessing this habit, please be careful when using this.">
                  <Tag style={{ marginRight: 5 }} color="warning">
                    <ExclamationCircleOutlined />
                  </Tag>
                </Tooltip>
              )}
              <Tag
                style={{
                  margin: 0,
                  paddingLeft: 2,
                  marginRight: 5,
                  paddingRight: 2,
                }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {item.types.map((v) => (
                    <div style={{ paddingRight: 2, paddingLeft: 2 }}>
                      {v === 'journey' ? (
                        <Tooltip placement="left" title={'Journey'}>
                          <FlagTwoTone twoToneColor="#059185" />
                        </Tooltip>
                      ) : v === 'routine' ? (
                        <Tooltip placement="left" title={'Routine'}>
                          <StarTwoTone twoToneColor="#E68372" />
                        </Tooltip>
                      ) : (
                        <Tooltip placement="left" title={'Challenge'}>
                          <TrophyTwoTone twoToneColor="#4F57CE" />
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </div>
              </Tag>
              <Badge>{Math.floor(item.time / 60)} m</Badge>
            </div>
          </Item>
        )}
      </Draggable>
    ));
  }
}

export default InnerHabitList;
