import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Modal,
  Input,
  message,
  Form,
  Tooltip,
  Typography,
  Tag,
  Popconfirm,
  Spin,
} from 'antd';
import _ from 'lodash';
import { EditTwoTone } from '@ant-design/icons';
import {
  getRoutines as fetchChilds,
  getCategoryRoutines as fetchParent,
  updateCategory as makeSaveParent,
  createCategory as makeNewParent,
  deleteCategory as makeDeleteParent,
} from './actions';
import DebounceSelect from './dynamicSearch';
import EditBackgroundForm from './backgroundPicker';

import Api from '../../../utils/api';

const CategoryRoutineBuilder = () => {
  const [childs, setChilds] = React.useState([]);
  const [backgroundForm] = Form.useForm();
  const [parent, setParent] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [backgroundVisible, setBackgroundVisible] = React.useState(false);
  const [form, setForm] = React.useState({});
  const [items, setItems] = React.useState([]);
  const bounceSelect = React.useRef();
  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [selectedBackgroundKey, setSelectedBackgroundKey] = useState('');

  const makeFetchChilds = async () => {
    const childs = await fetchChilds();
    setChilds(childs.data);
  };

  const makeFetchParent = async () => {
    const parents = await fetchParent();
    setParent(parents.data);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    setLoading(true);
    await makeFetchChilds();
    await makeFetchParent();
    setLoading(false);
  };

  const openModel = (data) => {
    setModalVisible(true);
    setForm(data);
    setItems(data.routinehabit);
  };

  const editBackground = (record) => {
    console.log('data', record);
    backgroundForm.setFieldsValue({
      ...record,
    });
    showBackgroundModal();
  };

  const showBackgroundModal = () => {
    setBackgroundVisible(true);
  };

  const columns = [
    {
      title: 'Index',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Parent',
      dataIndex: 'parent',
      key: 'parent',
      render: (text, record) => {
        return (
          <>
            <Tag color="blue">{record.parent}</Tag>
            <Tag color="green">{record.parentIndex}</Tag>
          </>
        );
      },
      filters: [...new Set(parent.map((item) => item.parent))].map((item) => ({
        text: item,
        value: item,
      })),
      onFilter: (value, record) => record.parent.startsWith(value),
      filterSearch: (input, record) => record.parent.indexOf(input) > -1,
    },
    {
      title: 'Routine',
      dataIndex: 'routinehabit',
      key: 'routinehabit',
      render: (routinehabit) => routinehabit.length,
    },
    {
      title: 'Background',
      dataIndex: 'images',
      width: '10%',
      editable: false,
      render: (_, record) => {
        const isDuplicate = parent
          .filter((z) => z._id !== record._id)
          .some((v) => v.images?.small?.url === record?.images?.small?.url);
        return (
          <React.Fragment>
            <Tooltip title="Edit Routine">
              <Typography.Link onClick={() => editBackground(record)}>
                <EditTwoTone
                  style={{ fontSize: '16px', marginRight: 20 }}
                  className="site-form-item-icon"
                />
              </Typography.Link>
            </Tooltip>
            {(record.images?.medium?.url || record.images?.small?.url) && (
              <React.Fragment>
                {isDuplicate && <Tag color={'red'}>Already used</Tag>}
                <img
                  src={record.images?.small?.url || record.images?.medium?.url}
                  alt="background"
                  width={56}
                />
                {!record.images.small?.url && 'Small Not Found'}
                {!record.images.medium?.url && 'Medium Not Found'}
                {!record.images.big?.url && 'Big Not Found'}
                {!record.images.banner?.url && 'Banner Not Found'}
                {!record.images.original?.url && 'Original Not Found'}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      width: '1%',
      render: (_, data) => (
        <Button
          type="primary"
          onClick={() => {
            openModel(data);
          }}>
          Edit
        </Button>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      width: '1%',
      render: (_, data) => (
        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={async () => {
            await makeDeleteParent({
              _id: data._id,
            });
            await fetchAll();
          }}>
          <a style={{ color: 'red' }} href="#">
            Delete
          </a>
        </Popconfirm>
      ),
    },
  ];

  const onOk = async () => {
    setModalVisible(false);
    await makeSaveParent({
      ...form,
      routinehabit: items,
    });
    setForm({});
    setItems([]);
    setOptions([]);
    setValue([]);
    fetchAll();
  };

  const handleSaveBackground = (e) => {
    e.preventDefault();
    backgroundForm
      .validateFields()
      .then((values) => {
        setLoading(true);
        console.log('#### SAVING 1', values.key, selectedBackgroundKey);
        onEditBackground(values._id, selectedBackgroundKey);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancelBackground = async () => {
    backgroundForm.resetFields();
    setBackgroundVisible(false);
  };

  const updateBackground = async (data) =>
    Api.put('/routines/category/background', data);

  const updateBackgroundAction = async (key, backgroundKey) => {
    if (key && backgroundKey) {
      try {
        const res = await updateBackground({
          key,
          backgroundKey,
        });
        message.success('Routine Background Updated');
        return res;
      } catch (err) {
        message.error(err?.data?.message);
        console.log('Error occured on finish updated', err);
        throw new Error(err);
      }
    } else {
      message.error('Bir hata oluştu code:3');
    }
  };

  const onEditBackground = async (key, backgroundKey) => {
    console.log('Received values of form: ', key, backgroundKey);
    try {
      await updateBackgroundAction(key, backgroundKey);
      await fetchAll();
      setBackgroundVisible(false);
      backgroundForm.resetFields();
    } catch (err) {
      console.log("edit background'de error", err);
    }
    setLoading(false);
  };

  return (
    <Spin spinning={loading}>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <h1>Category Routine Builder</h1>
        <Button
          onClick={async () => {
            await makeNewParent();
            await fetchAll();
          }}>
          New Category
        </Button>
      </div>
      <Table columns={columns} dataSource={parent} />
      <Modal
        width={700}
        title="Edit Category Routine"
        visible={modalVisible}
        onOk={onOk}
        onCancel={() => {
          setForm({});
          setItems([]);
          setOptions([]);
          setValue([]);
          setModalVisible(false);
        }}
        okText="Save"
        cancelText="Cancel"
        confirmLoading={loading}
        destroyOnClose>
        <div style={{ height: 580 }}>
          <Input
            addonBefore="Parent Name"
            style={{ marginBottom: 5 }}
            placeholder="Parent"
            value={form.parent}
            onChange={(e) => {
              setForm({ ...form, parent: e.target.value });
            }}
          />
          <Input
            addonBefore="Parent Index"
            style={{ marginBottom: 5 }}
            placeholder="Parent Index"
            value={form.parentIndex}
            type="number"
            onChange={(e) => {
              setForm({ ...form, parentIndex: Number(e.target.value) });
            }}
          />
          <Input
            addonBefore="Category Name"
            style={{ marginBottom: 5 }}
            placeholder="Category"
            value={form.category}
            onChange={(e) => {
              setForm({ ...form, category: e.target.value });
            }}
          />
          <Input
            addonBefore="Category Index"
            type="number"
            style={{ marginBottom: 5 }}
            placeholder="Index"
            value={form.index}
            onChange={(e) => {
              setForm({ ...form, index: Number(e.target.value) });
            }}
          />
          <Input
            addonBefore="Width"
            style={{ marginBottom: 5 }}
            placeholder="Width"
            value={form.type}
            type="number"
            onChange={(e) => {
              setForm({ ...form, type: Number(e.target.value) });
            }}
          />

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <DebounceSelect
              showSearch
              refSelect={bounceSelect}
              value={value}
              options={options}
              setOptions={setOptions}
              placeholder="Search"
              fetchOptions={(query) => {
                return new Promise((resolve, reject) => {
                  if (query && query.length > 0) {
                    const data = childs.filter(({ routine: item }) =>
                      item.name.toLowerCase().includes(query.toLowerCase())
                    );
                    const options = data.map((item) => ({
                      value: JSON.stringify(item),
                      label: item.routine.name,
                    }));
                    resolve(options);
                  } else {
                    resolve([]);
                  }
                });
              }}
              onChange={(newValue) => {
                console.log(newValue);
                setValue(newValue);
              }}
              style={{
                width: '100%',
              }}
            />
            <Button
              disabled={_.isEmpty(value)}
              style={{
                marginLeft: 5,
              }}
              onClick={() => {
                if (value) {
                  const newValue = JSON.parse(value.value);
                  console.log(newValue);
                  const findRoutine = childs.find(
                    (item) => item._id === newValue._id
                  );
                  const isExists = items.find(
                    (item) =>
                      item.routinehabit.routine._id === findRoutine.routine._id
                  );
                  if (!isExists) {
                    if (findRoutine) {
                      setItems([
                        ...items,
                        {
                          // find max index of items
                          index: items.length,
                          routinehabit: findRoutine,
                        },
                      ]);
                    } else {
                      message.error('Routine not found');
                    }
                  } else {
                    message.error('Routine already exists');
                  }
                }
              }}>
              Add
            </Button>
          </div>
          <div
            style={{
              padding: 10,
              paddingRight: 10,
              paddingBottom: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <p style={{ paddingLeft: 40 }}>Name</p>
            <p style={{ flexDirection: 'row', display: 'flex' }}>
              <Tooltip placement="left" title={'Order in category'}>
                <p style={{ marginRight: 18 }}>Index</p>
              </Tooltip>
              <Tooltip placement="left" title={'0-Small 1-Full Width'}>
                <p style={{ marginRight: 13 }}>Width</p>
              </Tooltip>
              <p style={{ marginRight: 15 }}>Featured</p>
              <Tooltip
                placement="left"
                title={'0-default 1-force-dark 2-force-light'}>
                <p>Color</p>
              </Tooltip>
            </p>
          </div>
          <div style={{ height: 300, overflowY: 'scroll' }}>
            {items.map((item, index) => (
              <div
                style={{
                  display: 'flex',
                  padding: 10,
                  paddingBottom: 0,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <p>
                  <Button
                    type="primary"
                    onClick={() => {
                      setItems(
                        items.filter(
                          (v) => v.routinehabit._id !== item.routinehabit._id
                        )
                      );
                    }}
                    danger>
                    -
                  </Button>{' '}
                  <img
                    src={item.routinehabit.routine.images.small.url}
                    alt=""
                    width={56}
                  />
                  {item.routinehabit.routine.name}
                </p>
                <p>
                  <Input
                    type={'number'}
                    value={item.index}
                    style={{ width: 65, marginRight: 5 }}
                    onChange={(e) => {
                      const newItems = items.map((v) => {
                        if (v.routinehabit._id === item.routinehabit._id) {
                          v.index = Number(e.target.value);
                        }
                        return v;
                      });
                      setItems(newItems);
                    }}
                  />
                  <Input
                    type={'number'}
                    value={item.type || 0}
                    style={{ width: 50, marginRight: 5 }}
                    onChange={(e) => {
                      const newItems = items.map((v) => {
                        if (v.routinehabit._id === item.routinehabit._id) {
                          v.type = Number(e.target.value);
                        }
                        return v;
                      });
                      setItems(newItems);
                    }}
                  />
                  <Input
                    type={'number'}
                    value={item.featured || 0}
                    style={{ width: 50, marginRight: 5 }}
                    onChange={(e) => {
                      const newItems = items.map((v) => {
                        if (v.routinehabit._id === item.routinehabit._id) {
                          v.featured = Number(e.target.value);
                        }
                        return v;
                      });
                      setItems(newItems);
                    }}
                  />
                  <Input
                    type={'number'}
                    value={item.color || 0}
                    style={{ width: 50 }}
                    onChange={(e) => {
                      const newItems = items.map((v) => {
                        if (v.routinehabit._id === item.routinehabit._id) {
                          v.color = Number(e.target.value);
                        }
                        return v;
                      });
                      setItems(newItems);
                    }}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        title="Edit Background"
        visible={backgroundVisible}
        onOk={handleSaveBackground}
        okText="Save"
        width={1000}
        confirmLoading={loading}
        onCancel={handleCancelBackground}
        footer={[
          <Button
            key="back"
            onClick={handleCancelBackground}
            disabled={loading}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSaveBackground}>
            Submit
          </Button>,
        ]}>
        <EditBackgroundForm
          form={backgroundForm}
          onFinish={handleSaveBackground}
          onFinishFailed={() => {
            message.error('Something went wrong');
          }}
          setSelectedBackgroundKey={setSelectedBackgroundKey}
        />
      </Modal>
    </Spin>
  );
};

export default CategoryRoutineBuilder;
