import React, { useEffect } from 'react';
import logo from './dayful_compressed.png';
import styled from '@emotion/styled';
import GuidePage from '../GuidePage';
import PrivacyPage from '../PrivacyPage';
import TermsPage from '../TermsPage';
import MindrobePrivacyPage from '../MindrobePrivacyPage';
import MindrobeTermsPage from '../MindrobeTermsPage';
import './landing.css';
import { useLocation } from 'react-router';

const ButtonWrapper = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  height: 32px;
  transition: all 0.2s cubic-bezier(0.16, 0.64, 0.48, 1) 0s;
  /* background: rgb(244, 244, 245); */
  border-width: 2px;
  border-style: solid;
  border-color: #ffc146;
  border-radius: 4px;
  background: #ffffff94;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: row;
  user-select: none;
  padding: 8px;
  margin: 0px;
  color: #ffc146;
  font-size: 13px;
  line-height: 24px;
  text-decoration: capitilize;

  &:hover {
    color: #fff;
    background: #ffc146;
  }

  &:active,
  &:focus {
    transform: scale(0.95);
  }
`;

function App() {
  const [dashboardVisible, setDashboardVisible] = React.useState(false);
  const [username, setUsername] = React.useState(false);
  const path = useLocation();

  useEffect(() => {
    let user = localStorage.getItem('user');
    let accessToken = localStorage.getItem('token');
    user = JSON.parse(user);
    if (user && accessToken) {
      setUsername(user.username);
      setDashboardVisible(true);
    }
  });
  return path && path.pathname === '/privacy' ? (
    <PrivacyPage />
  ) : path && path.pathname === '/guide' ? (
    <GuidePage />
  ) : path && path.pathname === '/terms' ? (
    <TermsPage />
  ) : path && path.pathname === '/mindrobe/terms' ? (
    <MindrobeTermsPage />
  ) : path && path.pathname === '/mindrobe/privacy' ? (
    <MindrobePrivacyPage />
  ) : (
    <div>
      <a
        href="https://apps.apple.com/us/app/dayful-daily-routine-planner/id6448982519?itsct=apps_box_promote_link&itscg=30200"
        target="_blank"
        rel="noreferrer">
        <header
          className="App-header"
          style={{
            backgroundColor: '#121212',
          }}>
          <img
            src={logo}
            className="App-logo"
            alt="dayful: daily routine planner"
            style={{
              width: '90vw',
              maxWidth: '90vh',
              height: 'auto',
              maxHeight: '90vh',
              alignSelf: 'center',
              borderRadius: 40,
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.8,
              shadowRadius: 2,
              elevation: 1,
            }}
          />
        </header>
      </a>
    </div>
  );
}

export default App;
