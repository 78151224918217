import { Action, Dispatch } from 'redux';
import Api from '../../../utils/api';
import {
  FETCH_HABIT_ERROR,
  FETCH_HABIT_START,
  FETCH_HABIT_SUCCESS,
  HABIT_PUSH,
  HABIT_UPDATE,
  HABIT_ARCHIVE,
} from './types';

const getHabits = async () => Api.get('/habits');

export function makeFetchHabits() {
  return async function (dispatch) {
    try {
      await startAction(FETCH_HABIT_START, dispatch);
      const response = await getHabits();
      const { data } = response;
      dispatch({
        type: FETCH_HABIT_SUCCESS,
        data,
      });
    } catch (error) {
      await errorAction(FETCH_HABIT_ERROR, dispatch, error);
    }
  };
}

export function makePushHabit(data) {
  return async function (dispatch) {
    dispatch({
      type: HABIT_PUSH,
      data,
    });
  };
}

export function makeUpdateHabit(key, data) {
  return async function (dispatch) {
    dispatch({
      type: HABIT_UPDATE,
      key,
      data,
    });
  };
}

export function makeArchiveHabit(id) {
  return async function (dispatch) {
    dispatch({
      type: HABIT_ARCHIVE,
      id,
    });
  };
}

export const startAction = async (type, dispatch) => {
  await dispatch({
    type,
  });
};

export const errorAction = async (type, dispatch, error) => {
  await dispatch({
    type,
    error: error.statusText || error.data,
  });
};
