import { combineReducers } from 'redux';
import Auth from '../containers/DashboardPage/Auth/reducer';
import Habit from '../containers/DashboardPage/Habit/reducer';
import Journey from '../containers/DashboardPage/Journey/reducer';
import JourneyHabits from '../containers/DashboardPage/JourneyBuilder/reducer';
import Routine from '../containers/DashboardPage/Routine/reducer';
import RoutineHabits from '../containers/DashboardPage/RoutineBuilder/reducer';
import Challenge from '../containers/DashboardPage/Challenge/reducer';
import ChallengeHabits from '../containers/DashboardPage/ChallengeBuilder/reducer';
// import List from './reducers/list';

export default combineReducers({
  Auth,
  Habit,
  Journey,
  JourneyHabits,
  Routine,
  RoutineHabits,
  Challenge,
  ChallengeHabits,
});
